export const ROUTES = {
  home: '/',
  inicio: '/resultados',
  tv: '/tv',
  modalidades: '/modalidades',
  ligas: '/ligas',
  copas: '/copas',
  concursos: '/concursos',
  campeonatos: '/campeonatos',
  boleras: '/boleras',
  admin: '/admin',
  medios: '/medios',
  ligasMedios: '/medios/ligas',
  mediosCopas: '/medios/copas',
  concursosMedios: '/medios/concursos',
  campeonatosMedios: '/medios/campeonatos',
  error: '/404',
};
