import { Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const AnimacionTexto = ({ style = {} }) => {
  const [texto, setTexto] = useState('Bolo Palma');
  const modalidades = [
    'Bolo Palma',
    'Bolo Pasiego',
    'Pasabolo Tablón',
    'Pasabolo Losa',
  ];
  const [indiceModalidad, setIndiceModalidad] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      const nuevaModalidad =
        modalidades[(indiceModalidad + 1) % modalidades.length];
      animateCambiarModalidad(nuevaModalidad);
      setIndiceModalidad((indiceModalidad + 1) % modalidades.length);
    }, 2000);

    return () => clearInterval(intervalo);
  }, [indiceModalidad, modalidades]);

  const animateCambiarModalidad = (nuevaModalidad) => {
    let tempText = texto;
    const intervalo = setInterval(() => {
      if (tempText === '') {
        clearInterval(intervalo);
        animateEscribirModalidad(nuevaModalidad);
      } else {
        tempText = tempText.slice(0, -1);
        setTexto(tempText);
      }
    }, 100);
  };

  const animateEscribirModalidad = (nuevaModalidad) => {
    let tempText = '';
    let index = 0;
    const intervalo = setInterval(() => {
      if (index < nuevaModalidad.length) {
        tempText += nuevaModalidad[index];
        if (texto.length < 120) {
          setTexto((prevTexto) => prevTexto + tempText.slice(-1));
        }
        index++;
      } else {
        clearInterval(intervalo);
      }
    }, 100);
  };

  return (
    <Typography variant='h2' className='reveal' style={style}>
      Visualiza los resultados en tiempo real de todas las ligas, copas,
      concursos y campeonatos de {texto}
    </Typography>
  );
};

export default AnimacionTexto;
