import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont,Roboto',
      'Helvetica Neue',
      'Helvetica,Ubuntu,Arial,sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 22,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
    },
    h6: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: 12,
      color: '#999999',
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
      color: '#000000',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
      light: '#85f0b2',
      dark: '#15a452',
    },
    secondary: {
      main: '#33db7a',
      light: '#85f0b2',
    },
    background: {
      default: '#f2f2f2',
      paper: '#ffffff',
      dark: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#33db7a',
      disabled: '#cdcdcd',
    },
    leftSidebar: {
      background: '#eaeaea',
      selectedGroupOptionsBackground: '#d8d8d8',
      selectedGroupOptionsGrayBorder: '#a3a3a3',
      selectedGroupOptionsBlueBorder: '#33db7a',
      selectedGroupOptionsActiveSelection: '#d0d0d0',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: ({ ownerState }) => {
          if (ownerState.variant === 'filled' && ownerState.size == 'small')
            return '#fffff';
          else return '#000000';
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '48px',
          '@media (min-width: 0px)': {
            minHeight: '48px',
          },
          '@media (min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#33db7a',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#33db7a',
          },
          color: 'gray',
          textTransform: 'none',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          ':has(input[readonly]), :has(textarea[readonly]), :has(.MuiInputBase-readOnly)':
            {
              fieldset: { border: '1px solid rgba(0, 0, 0, 0.23) !important' },
              label: { color: '#33db7a' },
              '& .MuiInputBase-input': {
                cursor: 'text',
                userSelect: 'text',
              },
              '.MuiSelect-icon': {
                display: 'none',
              },
              '.MuiAutocomplete-endAdornment': {
                display: 'none',
              },
            },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          outline: 'none', // Quita el borde del modal
          boxShadow: 'none', // Opcional: Quita la sombra si no la necesitas
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
        colorPrimary: () => 'red',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#33db7a',
          },
          '&:hover fieldset': {
            borderColor: '#33db7a',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#33db7a',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#33db7a',
          },
          '&:hover fieldset': {
            borderColor: '#33db7a',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#33db7a',
          },
        },
      },
    },
  },
});
