import { useMemo, useState } from 'react';
import { useData } from '../../../Context/DataContext';
import { useTema } from '../../../Context/TemaContext';
import { Box, ListItemText } from '@material-ui/core';
import { modalidades } from '../../../utils/Functions';
import BannerModalidad from '../../Componentes/BannerModalidad';
import { ListItemButton } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CuadroDeTexto } from '../../Componentes/CuadroDeTexto';

const Modalidades = () => {
  const { ligas, copas } = useData();
  const { tema } = useTema();

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);

  const todo = useMemo(() => {
    const array = [];
    ligas.forEach((i) => {
      array.push({
        id: i.id,
        nombre: i.nombre,
        modalidad: i.modalidad,
        tipo: 'liga',
        año: i.año,
      });
    });
    copas.forEach((i) => {
      array.push({
        id: i.id,
        nombre: i.nombre,
        modalidad: i.modalidad,
        tipo: 'copa',
        año: i.año,
      });
    });
    return array;
  }, [ligas, copas]);

  return (
    <Box
      style={{
        backgroundColor: tema.backgroundColor,
        transition: 'background-color 0.5s ease, color 1s ease',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        marginTop: 12,
      }}
    >
      <Box style={{ width: '95%', alignSelf: 'center', marginBottom: 12 }}>
        <CuadroDeTexto
          label='Buscar liga, copa...'
          variant='outlined'
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          fullWidth
          debounceable
        />
      </Box>
      <Box
        style={{
          flex: '1 1 auto',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Box
              style={{
                height: height,
                maxHeight: height,
                width: width,
                overflowY: 'auto',
              }}
            >
              {search !== '' || filter ? (
                <Box
                  style={{
                    flex: 1,
                    width: '100%',
                  }}
                >
                  {todo
                    .filter((item) => {
                      const nombre = item.nombre.toUpperCase();
                      if (nombre.includes(search.toUpperCase())) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                    .map((item) => (
                      <ListItemButton component='a' href='#simple-list'>
                        <ListItemText primary={item.nombre} />
                      </ListItemButton>
                    ))}
                </Box>
              ) : (
                <Box>
                  {modalidades.map((item) => (
                    <BannerModalidad
                      key={item.texto}
                      item={item}
                      onPress={(i) => setFilter(i)}
                      height={height}
                      width={width}
                    />
                  ))}
                </Box>
              )}
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

export default Modalidades;
