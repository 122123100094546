import { useEffect, useRef, useState } from 'react';
import { useTema } from '../../Context/TemaContext';
import { Box, ButtonBase, Dialog, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import Texto from './Texto';
import { RFPercentage } from '../../utils/Functions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateCalendar } from '@mui/x-date-pickers';

const Fechas = ({
  fechaSeleccionada,
  setFechaSeleccionada,
  setLoading,
  loading,
}) => {
  const { tema } = useTema();
  const [dias, setDias] = useState([]);
  const [visible, setVisible] = useState(false);

  const scrollRef = useRef(null);

  const cargarDias = (dia) => {
    const startOfWeek = moment(dia).startOf('isoWeek');
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.clone().add(i, 'days'));
    }

    setDias(['mas', ...days.map((d) => d.format('YYYY-MM-DD')), 'mas']);
  };

  useEffect(() => {
    cargarDias(moment(fechaSeleccionada).format('YYYY-MM-DD'));
  }, [fechaSeleccionada]);

  const onPress = (fecha) => {
    if (fecha === 'mas') {
      setVisible(true);
    } else {
      if (fecha !== fechaSeleccionada) {
        setLoading(true);
        setFechaSeleccionada(fecha);
      }
    }
  };

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Dialog
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 12,
        }}
        open={visible}
        onClose={() => setVisible(false)}
      >
        <Box
          style={{
            padding: 12,
            borderRadius: 12,
          }}
        >
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateCalendar
              value={moment(fechaSeleccionada)}
              onChange={(newValue) => {
                setFechaSeleccionada(moment(newValue).format('YYYY-MM-DD'));
                setVisible(null);
              }}
              showDaysOutsideCurrentMonth
              displayWeekNumber
              container='inline'
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Dialog>
      <Box
        ref={scrollRef}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'hidden',
          overflowY: 'hidden',
          justifyContent: 'center',
        }}
      >
        {dias.map((item, index) => {
          return (
            <ButtonBase
              onClick={() => onPress(item)}
              key={index}
              id={item}
              style={{
                flex: index === 0 || index === dias.length - 1 ? 0.5 : 1,
                borderRadius: 12,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: item === 'mas' ? 'center' : 'space-between',
                alignItems: 'center',
                padding: 2,
                paddingBottom: 8,
                margin: 12,
                marginBottom: 8,
                marginLeft: window.innerWidth < 600 ? 3 : 7,
                marginRight: window.innerWidth < 600 ? 3 : 7,
                border: `1px solid ${tema.button.default.borderColor}`,
                backgroundColor:
                  fechaSeleccionada === item
                    ? tema.primary
                    : tema.backgroundColor,
                transition: 'background-color 0.5s ease, color 1s ease',
                cursor: 'pointer',
              }}
            >
              {item === 'mas' ? (
                <>
                  <Texto
                    style={{
                      fontSize: RFPercentage(1),
                      fontWeight: '500',
                      maxWidth: '90%',
                      textAlign: 'center',
                      color:
                        fechaSeleccionada !== item &&
                        item === moment().format('YYYY-MM-DD')
                          ? tema.primary
                          : tema.texto,
                    }}
                  ></Texto>
                  <Texto
                    style={{
                      fontSize: RFPercentage(2.4),
                      fontWeight: '500',
                      textAlign: 'center',
                      color: tema.texto,
                    }}
                  >
                    {index === 0 ? '<' : '>'}
                  </Texto>
                  <Texto
                    style={{
                      fontSize: RFPercentage(0.8),
                      fontWeight: '400',
                      maxWidth: '90%',
                      textAlign: 'center',
                      color:
                        fechaSeleccionada !== item &&
                        item === moment().format('YYYY-MM-DD')
                          ? tema.primary
                          : tema.texto,
                    }}
                  ></Texto>
                </>
              ) : (
                <>
                  <Texto
                    numberOfLines={4}
                    style={{
                      fontSize: RFPercentage(1.4),
                      fontWeight: '500',
                      maxWidth: '90%',
                      textAlign: 'center',
                      color:
                        fechaSeleccionada !== item &&
                        item === moment().format('YYYY-MM-DD')
                          ? tema.primary
                          : tema.texto,
                    }}
                  >
                    {moment(item).format('MMM')}
                  </Texto>
                  <Texto
                    numberOfLines={4}
                    style={{
                      fontSize: RFPercentage(2.6),
                      fontWeight: '600',
                      maxWidth: '90%',
                      textAlign: 'center',
                      color:
                        fechaSeleccionada !== item &&
                        item === moment().format('YYYY-MM-DD')
                          ? tema.primary
                          : tema.texto,
                    }}
                  >
                    {moment(item).format('DD')}
                  </Texto>
                  <Texto
                    numberOfLines={4}
                    style={{
                      fontSize: RFPercentage(1.2),
                      fontWeight: '400',
                      maxWidth: '90%',
                      textAlign: 'center',
                      color:
                        fechaSeleccionada !== item &&
                        item === moment().format('YYYY-MM-DD')
                          ? tema.primary
                          : tema.texto,
                    }}
                  >
                    {moment(item).format('ddd')}
                  </Texto>
                </>
              )}
            </ButtonBase>
          );
        })}
      </Box>
      {loading ? (
        <LinearProgress
          color={'secondary'}
          style={{ backgroundColor: tema.backgroundColor }}
        />
      ) : (
        <Box height={'4px'} />
      )}
    </Box>
  );
};

export default Fechas;
