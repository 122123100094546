import React from 'react';
import { useData } from '../../../../Context/DataContext';
import { useTema } from '../../../../Context/TemaContext';
import { Box } from '@material-ui/core';
import Texto from '../../../Componentes/Texto';
import IconoJugador from '../../../Componentes/IconoJugador';
import IconoPeña from '../../../Componentes/IconoPena';

const Campeonato = ({ item, border = true }) => {
  const { buscarJugador } = useData();
  const { tema } = useTema();

  return (
    <Box
      key={item.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: tema.backgroundColor,
        alignSelf: 'center',
        width: '99%',
        marginBottom: 8,
        marginTop: 4,
        ...(border && {
          borderBottom: `1px solid ${tema.button.default.borderColor}`,
        }),
        paddingBottom: 8,
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingHorizontal: 12,
        }}
      >
        <Texto
          style={{
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold',
          }}
        >
          {item.titulo}
        </Texto>
        {item.jugador ? (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: 90,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 90,
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <IconoJugador
                jugador={item.jugador}
                peña={true}
                size={'mediano'}
                route={'Noticias'}
              />
              <Texto
                numberOfLines={1}
                style={{
                  fontSize: item.jugador2 ? 12 : 18,
                  fontWeight: 'bold',
                }}
              >
                {buscarJugador(item.jugador).nombre}
              </Texto>
            </Box>
            {item.jugador2 && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 90,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                }}
              >
                <IconoJugador
                  jugador={item.jugador2}
                  peña={true}
                  size={'mediano'}
                  route={'Noticias'}
                />
                <Texto
                  numberOfLines={1}
                  style={{ fontSize: 12, fontWeight: 'bold' }}
                >
                  {buscarJugador(item.jugador2).nombre}
                </Texto>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 70,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconoPeña peñaId={item.peña} size={50} />
            <Texto
              style={{
                fontSize: 12,
              }}
            >
              {item.peña}
            </Texto>
          </Box>
        )}
        {item.record !== undefined && item.record === true && (
          <Texto
            style={{
              color: '#FFB400',
              fontSize: 12,
            }}
          >
            {`Record`}
          </Texto>
        )}
        {item.bolos && (
          <Texto
            style={{
              color: 'grey',
              fontSize: 10,
            }}
          >
            {`Bolos: ${item.bolos}`}
          </Texto>
        )}
      </Box>
    </Box>
  );
};

export default Campeonato;
