import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@material-ui/core';
import { getRef } from '../../database/firebase.js';
import moment from 'moment/moment.js';
import { useData } from '../../Context/DataContext.js';
import { onSnapshot } from 'firebase/firestore';
import { useConfig } from '../../Context/ConfigContext.js';
import PartidoComponente from '../Componentes/PartidoComponente.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import ConcursoComponente from '../Componentes/ConcursoComponente.js';
import HeaderItem from './HeaderItem.js';
import { useUsuario } from '../../Context/UsuarioContext.js';
import { RFPercentage, hashCode } from '../../utils/Functions.js';
import { useTema } from '../../Context/TemaContext.js';
import Texto from '../Componentes/Texto.js';
import { ButtonBase } from '@mui/material';
import AvatarUsuario from '../Componentes/AvatarUsuario.js';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export const MovilInicio = () => {
  const {
    datosLoading,
    ligas,
    buscarCompeticion,
    buscarConcurso,
    categorias,
    categoriasFix,
    jugadores,
  } = useData();
  const phoneRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (phoneRef.current) {
        const { left, top, width, height } =
          phoneRef.current.getBoundingClientRect();
        const x = (e.clientX - left) / width - 0.5;
        const y = (e.clientY - top) / height - 0.5;

        phoneRef.current.style.transform = `
          perspective(1000px)
          rotateY(${x * 5}deg)
          rotateX(${-y * 5}deg)
          translateZ(50px)
        `;
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [isHovered]);

  const { filtro } = useConfig();
  const { usuario } = useUsuario();
  const { tema } = useTema();

  const [partidos, setPartidos] = useState([]);
  const [tiradas, setTiradas] = useState([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [filter, setFilter] = useState(filtro);

  const [loadingPartidos, setLoadingPartidos] = useState(true);
  const [loadingTiradas, setLoadingTiradas] = useState(true);
  const [dias, setDias] = useState([]);

  const cargarDias = (dia) => {
    const days = [];

    // Agregar dos días antes, el día actual y dos días después
    for (let i = -2; i <= 2; i++) {
      days.push(moment(dia).clone().add(i, 'days'));
    }

    setDias([...days.map((d) => d.format('YYYY-MM-DD'))]);
  };
  useEffect(() => {
    cargarDias(moment(fechaSeleccionada).format('YYYY-MM-DD'));
  }, []);

  const suscribePartidos = async (fecha) => {
    const partidosRef = await getRef('partidos', [
      {
        type: 'where',
        param: 'fecha',
        condition: '==',
        value: fecha,
      },
      { type: 'orderBy', param: 'hora' },
    ]);

    const unsub = onSnapshot(partidosRef, async (docsSnap) => {
      const partidosArray = docsSnap.docs.map((doc) => {
        const data = doc.data();
        const liga = buscarCompeticion(data.liga);

        return {
          ...data,
          id: doc.id,
          orden: liga?.orden || hashCode(liga.id) * 10000,
          competicion: liga,
          idReferencia: liga.id,
        };
      });

      const partidosOrdenados = partidosArray.sort((a, b) => {
        if (a.orden !== b.orden) {
          return a.orden - b.orden;
        } else {
          return a.hora - b.hora;
        }
      });

      const partidosConHeaders = [];

      partidosOrdenados.forEach((item, index) => {
        if (
          index === 0 ||
          (item.liga && partidosOrdenados[index - 1].liga !== item.liga)
        ) {
          const competicion = item.competicion;
          const titulo =
            filter && filter.modalidades && filter.modalidades.length === 1
              ? `${competicion.nombre || ''}`
              : `${competicion.nombre || ''} ${
                  competicion.clasificacion ? '-' : ''
                } ${
                  competicion && competicion.clasificacion
                    ? competicion.modalidad
                    : ''
                }`;

          partidosConHeaders.push({
            id: item.liga,
            isHeader: true,
            liga: competicion,
            titulo: titulo,
            idReferencia: item.liga,
          });
        }
        partidosConHeaders.push(item);
      });

      setPartidos(partidosConHeaders);
      setLoadingPartidos(false);
    });

    return unsub;
  };

  const suscribeConcursos = async (fecha) => {
    const tiradasRef = await getRef('tiradas', [
      {
        type: 'where',
        param: 'fecha',
        condition: '==',
        value: fecha,
      },
      { type: 'orderBy', param: 'hora' },
    ]);

    const unsub = onSnapshot(tiradasRef, async (docsSnap) => {
      if (docsSnap) {
        const tiradasArray = docsSnap.docs.map((doc) => {
          const data = doc.data();
          const concurso = buscarConcurso(data.concursoId);
          return {
            ...data,
            id: doc.id,
            orden:
              concurso.categorías && concurso.categorías[0]
                ? categorias.find((i) => i.id === concurso.categorías[0])?.orden
                : 1000000,
            concurso: concurso,
            idReferencia: concurso.id,
          };
        });

        const tiradasOrdenadas = tiradasArray
          .filter((i) => i.concursoId !== 'El Corro')
          .sort((a, b) => {
            if (a.orden !== b.orden) {
              return a.orden - b.orden;
            } else if (a.concursoId !== b.concursoId) {
              return a.concursoId < b.concursoId
                ? -1
                : a.concursoId > b.concursoId
                ? 1
                : 0;
            } else {
              return a.hora - b.hora;
            }
          });

        const tiradasConHeaders = [];

        tiradasOrdenadas.forEach((item, index) => {
          if (
            index === 0 ||
            (item.concursoId &&
              tiradasOrdenadas[index - 1].concursoId !== item.concursoId)
          ) {
            const concurso = item.concurso;
            const titulo =
              concurso.tipo === 'Final'
                ? `Final - ${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : concurso.tipo === 'Clasificacion'
                ? `${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : `${concurso.tipo} ${
                    concurso.individual ? 'Individual' : 'por parejas'
                  } ${categoriasFix(concurso.categorías)} - ${
                    concurso.modalidad
                  }`;

            tiradasConHeaders.push({
              id: item.concursoId,
              isHeader: true,
              concurso: concurso,
              titulo: titulo,
              idReferencia: item.concursoId,
            });
          }
          tiradasConHeaders.push(item);
        });

        setTiradas(tiradasConHeaders);
        setLoadingTiradas(false);
      }
    });

    return unsub;
  };

  useEffect(() => {
    if (!datosLoading && fechaSeleccionada) {
      suscribeConcursos(fechaSeleccionada);
      suscribePartidos(fechaSeleccionada);
    }
  }, [fechaSeleccionada, datosLoading]);

  const onPress = (fecha) => {
    if (fecha !== fechaSeleccionada) {
      setLoadingPartidos(true);
      setLoadingTiradas(true);
      setFechaSeleccionada(fecha);
    }
  };

  const renderItem = useCallback((item, index, data) => {
    return (
      <Box
        key={item.id}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          borderRadius:
            index === 0 || data[index - 1]?.idReferencia !== item.idReferencia
              ? '12px 12px 0px 0px'
              : index === data.length - 1 ||
                data[index + 1]?.idReferencia !== item.idReferencia
              ? '0px 0px 12px 12px'
              : 0,
          overflow: 'hidden',
          marginBottom:
            index === data.length - 1 ||
            data[index + 1]?.idReferencia !== item.idReferencia
              ? 12
              : 0,
          transform: 'translateY(20px)',
          animation: 'fadeIn 0.3s forwards',
          animationDelay: `${index * 0.05}s`,
          listStyle: 'none',
        }}
      >
        {item.isHeader && <HeaderItem key={index} item={item} scale={0.7} />}
        {item.ch_l !== undefined && (
          <PartidoComponente key={index} partido={item} scale={0.5} />
        )}
        {!item.isHeader && item.ch_l === undefined && (
          <ConcursoComponente
            key={index}
            tirada={item}
            concurso={item.concurso}
            route={'Drawer'}
            scale={0.5}
          />
        )}
      </Box>
    );
  }, []);

  const flatData = useMemo(() => {
    const {
      enDirecto,
      televisado,
      modalidades,
      ligas: filterLigas,
      copas,
      peñas,
      tipoConcurso,
      categorias,
      jugadores: filterJugadores,
    } = filter;

    const partidosOrdenados = partidos.filter((partido) => {
      const l = ligas.find((i) => i.id === partido.liga) || {};

      return (
        partido.isHeader ||
        ((!enDirecto ||
          (partido.estado === 'Sin jugar' &&
            ((moment().format('YYYY-MM-DD') === partido.fecha &&
              moment().format('HH:mm') >= partido.hora) ||
              moment().format('YYYY-MM-DD') > partido.fecha))) &&
          (!televisado || partido.televisiones.length > 0) &&
          (modalidades.length === 0 ||
            (l.modalidad && modalidades.includes(l.modalidad))) &&
          (filterLigas.length === 0 ||
            l.tipo !== 'liga' ||
            filterLigas.includes(partido.liga)) &&
          (!copas ||
            copas.length === 0 ||
            l.tipo !== 'copa' ||
            (copas && copas.includes(partido.liga))) &&
          (peñas.length === 0 ||
            peñas.includes(partido.local) ||
            peñas.includes(partido.visitante)))
      );
    });

    const tiradasOrdenadas = tiradas.filter((tirada) => {
      const c = tirada.concurso;
      const jugador = jugadores.find(
        (i) => i.usuario === usuario.nombreUsuario
      );

      return (
        tirada.isHeader ||
        (tirada.concursoId !== 'El Corro' &&
          ((jugador &&
            c.categorías &&
            c.categorías.some((item) => jugador.categorías.includes(item))) ||
            (tirada.jugadores1 !== undefined &&
              (tirada.jugadores1[0] !== '' || tirada.jugadores2[0] !== '')) ||
            (tirada.jugador1 !== undefined &&
              (tirada.jugador1 !== '' || tirada.jugador2 !== ''))) &&
          (!televisado || (c.televisiones && c.televisiones.length > 0)) &&
          (!tipoConcurso ||
            tipoConcurso.length === 0 ||
            tipoConcurso.includes(c.tipo)) &&
          (modalidades.length === 0 ||
            (c.modalidad && modalidades.includes(c.modalidad))) &&
          (!categorias ||
            categorias.length === 0 ||
            (c.categorías &&
              categorias.some((i) => c.categorías.includes(i)))) &&
          (!filterJugadores ||
            filterJugadores.length === 0 ||
            filterJugadores.includes(tirada.jugador1 || tirada.jugadores1) ||
            filterJugadores.includes(tirada.jugador2 || tirada.jugadores2)))
      );
    });

    return loadingPartidos || loadingTiradas
      ? []
      : [
          ...partidosOrdenados,
          ...tiradasOrdenadas.filter(
            (tirada) =>
              !tirada.isHeader ||
              tiradasOrdenadas.filter((i) => i.concursoId === tirada.id)
                .length > 0
          ),
        ];
  }, [
    partidos,
    tiradas,
    filter,
    fechaSeleccionada,
    loadingPartidos,
    loadingTiradas,
  ]);

  return (
    <div
      ref={phoneRef}
      className={`phone-container ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='phone-frame'>
        <div className='phone-screen'>
          <main className='phone-content'>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: 12,
                  marginLeft: 6,
                }}
              >
                <img
                  src={require('../../assets/nuevoLogo.png')}
                  style={{
                    height: RFPercentage(3.5),
                    width: RFPercentage(3.5),
                  }}
                />
                {usuario && usuario.nombreUsuario !== 'Anónimo' ? (
                  <AvatarUsuario usuario={usuario} scale={0.8} />
                ) : (
                  <AccountCircle sx={{ color: tema.colors[400] }} />
                )}
              </Box>
              <Box
                style={{
                  paddingLeft: 6,
                  paddingRight: 6,
                  backgroundColor: tema.backgroundColor,
                  transition: 'background-color 0.5s ease, color 1s ease',
                  borderRadius: 12,
                  marginBottom: 12,
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    justifyContent: 'center',
                  }}
                >
                  {dias.map((item, index) => {
                    return (
                      <ButtonBase
                        onClick={() => onPress(item)}
                        key={index}
                        id={item}
                        style={{
                          flex: 1,
                          borderRadius: 12,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 2,
                          paddingBottom: 8,
                          margin: 6,
                          marginBottom: 8,
                          marginLeft: 2,
                          marginRight: 2,
                          border: `1px solid ${tema.button.default.borderColor}`,
                          backgroundColor:
                            fechaSeleccionada === item
                              ? tema.primary
                              : tema.backgroundColor,
                          transition:
                            'background-color 0.5s ease, color 1s ease',
                          cursor: 'pointer',
                        }}
                      >
                        <Texto
                          numberOfLines={4}
                          style={{
                            fontSize: RFPercentage(0.6),
                            fontWeight: '500',
                            maxWidth: '90%',
                            textAlign: 'center',
                            color:
                              fechaSeleccionada !== item &&
                              item === moment().format('YYYY-MM-DD')
                                ? tema.primary
                                : tema.texto,
                          }}
                        >
                          {moment(item).format('MMM')}
                        </Texto>
                        <Texto
                          numberOfLines={4}
                          style={{
                            fontSize: RFPercentage(1.6),
                            fontWeight: '600',
                            maxWidth: '90%',
                            textAlign: 'center',
                            color:
                              fechaSeleccionada !== item &&
                              item === moment().format('YYYY-MM-DD')
                                ? tema.primary
                                : tema.texto,
                          }}
                        >
                          {moment(item).format('DD')}
                        </Texto>
                        <Texto
                          numberOfLines={4}
                          style={{
                            fontSize: RFPercentage(0.8),
                            fontWeight: '400',
                            maxWidth: '90%',
                            textAlign: 'center',
                            color:
                              fechaSeleccionada !== item &&
                              item === moment().format('YYYY-MM-DD')
                                ? tema.primary
                                : tema.texto,
                          }}
                        >
                          {moment(item).format('ddd')}
                        </Texto>
                      </ButtonBase>
                    );
                  })}
                </Box>
              </Box>
              <Box
                style={{
                  flex: 1,
                  borderRadius: 12,
                  overflow: 'hidden',
                }}
              >
                <AutoSizer>
                  {({ height, width }) => (
                    <Box
                      style={{
                        height: height,
                        maxHeight: height,
                        width: width,
                        overflowY: 'auto',
                      }}
                    >
                      {flatData.map((item, index) =>
                        renderItem(item, index, flatData)
                      )}
                      {flatData.length === 0 &&
                        !loadingPartidos &&
                        !loadingTiradas && (
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: height,
                              opacity: 0.15,
                            }}
                          >
                            <img
                              src={require('../../assets/nuevoLogo.png')}
                              style={{
                                height: RFPercentage(26),
                                width: RFPercentage(26),
                                borderRadius: 12,
                                marginBottom: 48,
                              }}
                              alt='LOGO'
                            />
                          </Box>
                        )}
                    </Box>
                  )}
                </AutoSizer>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: 12,
                  marginLeft: 6,
                }}
              >
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <VideogameAssetIcon />
                </Box>
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NewspaperIcon />
                </Box>
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <HomeIcon style={{ color: tema.colors[500] }} />
                </Box>
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EmojiEventsIcon />
                </Box>
                <Box
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <PlagiarismIcon />
                </Box>
              </Box>
            </Box>
          </main>
        </div>
      </div>
    </div>
  );
};
