import React from 'react';
import Login from './Login.js';
import {
  Box,
  Button,
  Drawer,
  Paper,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useUsuario } from '../../Context/UsuarioContext.js';
import { useTheme } from '@mui/material/styles';
import Texto from '../Componentes/Texto.js';
import AvatarUsuario from '../Componentes/AvatarUsuario.js';
import { AccountCircle } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ROUTES } from '../Router/routes.js';
import CampaignIcon from '@mui/icons-material/Campaign';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { useTema } from '../../Context/TemaContext.js';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const DrawerUsuario = ({ visible, setVisible }) => {
  const { usuario, deleteUser } = useUsuario();
  const { tema } = useTema();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const classes = useStyles();

  const Boton = (props) => {
    const { disabled, path, icon, label } = props;

    const navigate = useNavigate();

    return (
      <Paper
        className={disabled ? classes.buttonDisabled : classes.button}
        style={{
          height: '20vh',
          width: '80%',
          borderRadius: 20,
        }}
      >
        <Box
          display='flex'
          style={{
            borderRadius: 20,
            height: '100%',
            width: '100%',
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.2)' : 'white',
          }}
        >
          <Button
            disabled={disabled}
            onClick={() => navigate(path)}
            aria-label={label}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <Box
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box p={2}>{icon}</Box>
              <Typography className={classes.title} variant='h5'>
                {label}
              </Typography>
            </Box>
          </Button>
        </Box>
      </Paper>
    );
  };

  return (
    <Drawer anchor={'right'} open={visible} onClose={() => setVisible(false)}>
      <div
        role='presentation'
        style={{
          width: isMobile ? '90vw' : '30vw',
          height: '100%',
          backgroundColor: tema.backgroundColor,
        }}
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <Box
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
          }}
        >
          {usuario.nombreUsuario !== 'Anónimo' ? (
            <Box
              style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingTop: '10vh',
              }}
            >
              <Box style={{ marginTop: 24 }}>
                {usuario && usuario.nombreUsuario !== 'Anónimo' ? (
                  <AvatarUsuario size={200} usuario={usuario} />
                ) : (
                  <AccountCircle />
                )}
              </Box>
              <Texto style={{ fontWeight: '600' }}>
                {usuario.nombreUsuario}
              </Texto>
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                {usuario.permisos && usuario.permisos.includes('Admin') && (
                  <Box
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Boton
                      label={'Admin'}
                      path={ROUTES.admin}
                      icon={
                        <AdminPanelSettingsIcon style={{ fontSize: '10vh' }} />
                      }
                    />
                  </Box>
                )}
                {usuario.permisos &&
                  usuario.permisos.some((i) =>
                    ['Prensa', 'Admin'].includes(i)
                  ) && (
                    <Box
                      style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Boton
                        label={'Medios'}
                        path={ROUTES.medios}
                        icon={<CampaignIcon style={{ fontSize: '10vh' }} />}
                      />
                    </Box>
                  )}
                <Box
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Boton
                    label={'TV'}
                    path={ROUTES.tv}
                    icon={<LiveTvIcon style={{ fontSize: '10vh' }} />}
                  />
                </Box>
              </Box>
              <Button
                style={{
                  marginBottom: 24,
                  height: '48px',
                  width: isMobile ? '80vw' : '25vw',
                  borderRadius: '0.4rem',
                  fontWeight: 500,
                }}
                variant='contained'
                onClick={() => {
                  deleteUser();
                }}
              >
                Cerrar sesión
              </Button>
            </Box>
          ) : (
            <Login />
          )}
        </Box>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'black',
      transform: 'scale(1.05)',
      '& .MuiSvgIcon-colorPrimary': {
        color: 'black',
      },
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  buttonDisabled: {
    borderRadius: '0.8rem',
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  icon: {
    '& svg': {
      fontSize: 150,
      height: '100%',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
  },
}));
