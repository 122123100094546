import React from 'react';
import IconoJugador from './IconoJugador.js';
import { memo } from 'react';
import Texto from './Texto.js';
import { Box } from '@mui/material';
import { useData } from '../../Context/DataContext.js';
import { useTema } from '../../Context/TemaContext.js';
import { RFPercentage } from '../../utils/Functions.js';

const ConcursoComponente = memo((props) => {
  const { tirada, onPress = null, scale = 1 } = props;

  const { buscarJugador, jugadores } = useData();
  const { tema } = useTema();

  return (
    <Box
      onClick={() => {
        if (onPress === null) {
          // openBottomSheet();
        } else {
          onPress();
        }
      }}
      key={tirada.id}
      style={{
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.1)',
        paddingTop: 5 * scale,
        paddingBottom: 5 * scale,
        backgroundColor: tema.backgroundColor,
        transition: 'background-color 0.5s ease, color 1s ease',
        position: 'relative',
        minHeight: 50 * scale,
        width: '100%',
      }}
    >
      {tirada.tipo !== undefined && tirada.tipo !== '' && (
        <Texto
          allowFontScaling={false}
          style={{
            textAlign: 'center',
            fontSize: RFPercentage(1.6 * scale),
            fontWeight: '300',
          }}
        >
          {tirada.tipo}
        </Texto>
      )}
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 3 * scale,
          paddingTop:
            tirada.tipo !== undefined && tirada.tipo !== '' ? 0 : 3 * scale,
          width: '100%',
        }}
      >
        {tirada.jugadores1 ? (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                paddingRight: 5 * scale,
              }}
            >
              <Texto
                allowFontScaling={false}
                numberOfLines={2}
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingRight: 5,
                  fontSize: RFPercentage(1.4 * scale),
                }}
              >
                {buscarJugador(tirada.jugadores1[0]).nombre}
              </Texto>
              <Texto
                allowFontScaling={false}
                numberOfLines={2}
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingRight: 5,
                  fontSize: RFPercentage(1.4 * scale),
                }}
              >
                {buscarJugador(tirada.jugadores1[1]).nombre}
              </Texto>
            </Box>
            <Box
              style={{
                position: 'relative',
                minWidth: 70 * scale,
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 55 * scale,
                marginLeft: 12 * scale,
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  left: 0,
                  zIndex: 100,
                }}
              >
                <IconoJugador
                  jugador={tirada.jugadores1[0]}
                  size={'mediano'}
                  scale={scale}
                />
              </Box>
              <Box
                style={{
                  position: 'absolute',
                  left: 25 * scale,
                  zIndex: 110,
                }}
              >
                <IconoJugador
                  jugador={tirada.jugadores1[1]}
                  size={'mediano'}
                  scale={scale}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Texto
                allowFontScaling={false}
                numberOfLines={2}
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingRight: 12 * scale,
                  fontSize: RFPercentage(2 * scale),
                }}
              >
                {buscarJugador(tirada.jugador1).nombre}
              </Texto>
              <IconoJugador
                jugador={tirada.jugador1}
                size={'mediano'}
                scale={scale}
              />
            </Box>
          </Box>
        )}
        {(tirada.bolos1 === 0 && tirada.bolos2 === 0) ||
        (tirada.chicos1 === 0 && tirada.chicos2 === 0) ? (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: RFPercentage(14 * scale),
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {tirada.suspendida !== undefined && tirada.suspendida === true && (
              <Box
                style={{
                  display: 'flex',
                  width: 70 * scale,
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 0,
                }}
              >
                <Texto
                  allowFontScaling={false}
                  numberOfLines={1}
                  style={{
                    fontSize: RFPercentage(1 * scale),
                    fontWeight: '500',
                  }}
                >
                  {'Suspendido'}
                </Texto>
              </Box>
            )}
            <Texto
              allowFontScaling={false}
              numberOfLines={1}
              style={{
                fontSize: RFPercentage(2.4 * scale),
                paddingLeft: 4 * scale,
                paddingRight: 4 * scale,
                fontWeight: '500',
              }}
            >
              {tirada.hora}
            </Texto>
          </Box>
        ) : (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              minWidth: RFPercentage(14 * scale),
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {tirada.suspendida !== undefined && tirada.suspendida === true && (
              <Box
                style={{
                  display: 'flex',
                  width: 70 * scale,
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 0,
                }}
              >
                <Texto
                  allowFontScaling={false}
                  numberOfLines={1}
                  style={{
                    fontSize: RFPercentage(1 * scale),
                    fontWeight: '500',
                    marginBottom: 4 * scale,
                  }}
                >
                  {'Suspendido'}
                </Texto>
              </Box>
            )}
            <Texto
              allowFontScaling={false}
              numberOfLines={1}
              style={{
                fontSize: RFPercentage(2 * scale),
                fontWeight: '500',
              }}
            >{`${
              ((tirada.jugador1 !== undefined && tirada.jugador1 !== '') ||
                (tirada.jugadores1 !== undefined &&
                  tirada.jugadores1[0] !== '')) &&
              tirada.bolos1 !== undefined
                ? tirada.bolos1
                : ((tirada.jugador1 !== undefined && tirada.jugador1 !== '') ||
                    (tirada.jugadores1 !== undefined &&
                      tirada.jugadores1[0] !== '')) &&
                  tirada.chicos1 !== undefined
                ? tirada.chicos1
                : '       '
            }`}</Texto>

            <Texto
              allowFontScaling={false}
              numberOfLines={1}
              style={{
                fontSize: RFPercentage(2.4 * scale),
                fontWeight: '500',
                paddingLeft: 2 * scale,
                paddingRight: 2 * scale,
              }}
            >
              {(tirada.jugador1 !== undefined &&
                (tirada.jugador1 === '' || tirada.jugador2 === '')) ||
              (tirada.jugadores1 !== undefined &&
                (tirada.jugadores1[0] === '' || tirada.jugadores2[0] === ''))
                ? ' '
                : '-'}
            </Texto>

            <Texto
              allowFontScaling={false}
              numberOfLines={1}
              style={{
                fontSize: RFPercentage(2 * scale),
                fontWeight: '500',
              }}
            >{`${
              ((tirada.jugador2 !== undefined && tirada.jugador2 !== '') ||
                (tirada.jugadores2 !== undefined &&
                  tirada.jugadores2[0] !== '')) &&
              tirada.bolos2 !== undefined
                ? tirada.bolos2
                : ((tirada.jugador2 !== undefined && tirada.jugador2 !== '') ||
                    (tirada.jugadores2 !== undefined &&
                      tirada.jugadores2[0] !== '')) &&
                  tirada.chicos2 !== undefined
                ? tirada.chicos2
                : '       '
            }`}</Texto>
          </Box>
        )}
        {tirada.jugadores2 ? (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                position: 'relative',
                minWidth: 70 * scale,
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 55 * scale,
                marginRight: 12 * scale,
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  left: 0,
                  zIndex: 110,
                }}
              >
                <IconoJugador
                  jugador={tirada.jugadores2[0]}
                  size={'mediano'}
                  scale={scale}
                />
              </Box>
              <Box
                style={{
                  position: 'absolute',
                  left: 25 * scale,
                  zIndex: 100,
                }}
              >
                <IconoJugador
                  jugador={tirada.jugadores2[1]}
                  size={'mediano'}
                  scale={scale}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                paddingRight: 5 * scale,
              }}
            >
              <Texto
                allowFontScaling={false}
                numberOfLines={2}
                style={{
                  flex: 1,
                  textAlign: 'left',
                  paddingLeft: 5 * scale,
                  fontSize: RFPercentage(1.2 * scale),
                }}
              >
                {buscarJugador(tirada.jugadores2[0]).nombre}
              </Texto>
              <Texto
                allowFontScaling={false}
                numberOfLines={2}
                style={{
                  flex: 1,
                  textAlign: 'left',
                  paddingLeft: 5 * scale,
                  fontSize: RFPercentage(1.2 * scale),
                }}
              >
                {buscarJugador(tirada.jugadores2[1]).nombre}
              </Texto>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconoJugador
                jugador={tirada.jugador2}
                size={'mediano'}
                scale={scale}
              />
              <Texto
                allowFontScaling={false}
                numberOfLines={2}
                style={{
                  flex: 1,
                  textAlign: 'left',
                  paddingLeft: 12 * scale,
                  fontSize: RFPercentage(2 * scale),
                }}
              >
                {buscarJugador(tirada.jugador2).nombre}
              </Texto>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default ConcursoComponente;
