import requireTv from '../../database/requireTv';
import { memo } from 'react';
import { useData } from '../../Context/DataContext';
import { Box, Divider } from '@material-ui/core';
import Texto from '../Componentes/Texto';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Router/routes';
import { useTema } from '../../Context/TemaContext';
import { RFPercentage } from '../../utils/Functions';

const HeaderItem = memo(({ item, scale }) => {
  const { buscarConcurso, categoriasFix, buscarCompeticion, buscarCategoria } =
    useData();
  const { tema } = useTema();

  let navigate = useNavigate();

  if (item.liga !== undefined) {
    return (
      <Box
        key={item.titulo}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: tema.backgroundColor,
          transition: 'background-color 0.5s ease, color 1s ease',
        }}
        onClick={() => {
          if (item.liga.tipo !== 'amistoso') {
            const competicion = buscarCompeticion(item.id);
            if (item.liga.clasificacion === undefined) {
              navigate(ROUTES.copas, {
                state: {
                  modalidad: competicion.modalidad,
                  copaId: item.id,
                },
              });
            } else {
              navigate(ROUTES.ligas, {
                state: {
                  modalidad: competicion.modalidad,
                  ligaId: item.id,
                },
              });
            }
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderTopWidth: 1,
            borderTopColor: 'rgba(0, 0, 0, 0.04)',
            position: 'relative',
          }}
        >
          <Texto
            style={{
              fontWeight: '600',
              paddingVertical: 2,
              textAlign: 'center',
              paddingHorizontal: 4,
              fontSize: RFPercentage(1.6 * scale),
            }}
            numberOfLines={1}
          >
            {item.titulo}
          </Texto>
        </Box>
        <Divider sx={{ width: '100%', height: 10 * scale }} />
      </Box>
    );
  } else {
    const concurso = buscarConcurso(item.id);
    const categoria = buscarCategoria(concurso.categorías[0]);

    return (
      <Box
        key={item.titulo}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          borderRadius: '12px 12px 0px 0px',
          backgroundColor: tema.backgroundColor,
          transition: 'background-color 0.5s ease, color 1s ease',
          overflow: 'hidden',
        }}
        onClick={() => {
          if (concurso.tipo === 'Clasificación' || concurso.tipo === 'Final') {
            navigate(ROUTES.concursos, {
              state: {
                modalidad: categoria.modalidad,
                tipos: ['Clasificación', 'Final'],
                concursoId: concurso.id,
              },
            });
          } else {
            navigate(ROUTES.campeonatos, {
              state: {
                modalidad: categoria.modalidad,
                tipos: ['Campeonato Regional', 'Campeonato de España'],
                concursoId: concurso.id,
              },
            });
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Texto
              style={{
                fontWeight: '600',
                paddingVertical: 2,
                textAlign: 'center',
                paddingLeft: 4,
                paddingRight: 4,
                flex: 1,
                fontSize: RFPercentage(1.6 * scale),
              }}
              numberOfLines={1}
            >
              {!concurso.categorías
                ? '.'
                : concurso.tipo === 'Final'
                ? `Final - ${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : concurso.tipo === 'Clasificacion'
                ? `${concurso.nombre || ''} - ${
                    categoriasFix(concurso.categorías) || ''
                  }`
                : `${concurso.tipo} ${
                    concurso.individual ? 'Individual' : 'por parejas'
                  } ${categoriasFix(concurso.categorías)} - ${
                    concurso.modalidad
                  }`}
            </Texto>
            {concurso.televisiones !== undefined &&
              concurso.televisiones.map((item, index) => (
                <img
                  key={index}
                  src={requireTv(item.televisión)}
                  style={{
                    width: 17 * scale,
                    height: 17 * scale,
                    borderRadius: 60,
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                  cachePolicy={'memory-disk'}
                />
              ))}
          </Box>
        </Box>
        {/* <Divider sx={{ width: '100%', height: 10 }} /> */}
      </Box>
    );
  }
});

export default HeaderItem;
