import { Box } from '@material-ui/core';
import { useData } from '../../../../Context/DataContext';
import { useTema } from '../../../../Context/TemaContext';
import Texto from '../../../Componentes/Texto';
import { RFPercentage } from '../../../../utils/Functions';
import requireTv from '../../../../database/requireTv';
import IconoPeña from '../../../Componentes/IconoPena';
import IconoJugador from '../../../Componentes/IconoJugador';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Fichaje = ({ item, border = true }) => {
  const { buscarJugador, buscarPeña } = useData();
  const { tema } = useTema();

  return (
    <Box
      key={item.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: tema.backgroundColor,
        alignSelf: 'center',
        width: '99%',
        marginBottom: 8,
        marginTop: 4,
        ...(border && {
          borderBottom: `1px solid ${tema.button.default.borderColor}`,
        }),
        paddingBottom: 8,
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          top: 4,
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            paddingLeft: 4,
            minWidth: 20,
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 4,
          }}
        >
          {item.estado === 'Verificado' && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <VerifiedIcon style={{ color: '#00acee' }} />
              {item.editadoPorJugador && (
                <Texto style={{ marginLeft: 4, fontSize: RFPercentage(1.4) }}>
                  Verificado por el jugador
                </Texto>
              )}
            </Box>
          )}
          {item.estado === 'Rechazado' && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <CloseIcon style={{ color: 'red' }} />
              {item.editadoPorJugador && (
                <Texto style={{ marginLeft: 4, fontSize: RFPercentage(1.4) }}>
                  Rechazado por el jugador
                </Texto>
              )}
            </Box>
          )}
          {item.estado === 'Pendiente' && item.nVotos && (
            <Texto
              style={{
                marginLeft: 4,
                fontSize: RFPercentage(1.6),
              }}
            >
              {`${item.nVotos} votos`}
            </Texto>
          )}
        </Box>
        <Box
          style={{
            paddingRight: 4,
            minWidth: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {item.medio !== undefined && item.medio !== null && (
            <img
              src={requireTv(item.medio)}
              style={{
                width: 20,
                height: 20,
                borderRadius: 60,
              }}
            />
          )}
          {item.creador !== 'marcossaro' && (
            <Box
              style={{
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <Texto style={{ fontSize: RFPercentage(1) }}>
                {item.creador}
              </Texto>
            </Box>
          )}
        </Box>
      </Box>
      <Box style={{ width: '100%' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            width: '100%',
          }}
        >
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconoPeña peñaId={item.pasada} size={45} />
          </Box>
          <ArrowForwardIcon style={{ color: tema.texto }} />
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              marginVertical: 2,
            }}
          >
            <IconoJugador
              peña={false}
              jugador={item.jugador}
              size={'mediano'}
              route={'Noticias'}
            />
          </Box>
          <ArrowForwardIcon style={{ color: tema.texto }} />
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {item.nueva === 'Libre' ? (
              <Texto
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Libre
              </Texto>
            ) : (
              <IconoPeña peñaId={item.nueva} size={45} />
            )}
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            width: '100%',
          }}
        >
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Texto
              numberOfLines={3}
              style={{
                fontSize: RFPercentage(1.2),
                textAlign: 'center',
              }}
            >
              {buscarPeña(item.pasada).nombre}
            </Texto>
          </Box>
          <Box style={{ width: 30 }} />
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Texto
              style={{
                fontSize: RFPercentage(1.2),
                textAlign: 'center',
                marginTop: 5,
              }}
            >
              {buscarJugador(item.jugador)?.nombre}
            </Texto>
          </Box>
          <Box style={{ width: 30 }} />
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Texto
              numberOfLines={3}
              style={{
                fontSize: RFPercentage(1.2),
                textAlign: 'center',
              }}
            >
              {buscarPeña(item.nueva).nombre}
            </Texto>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Fichaje;
