import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { getRef } from '../../../database/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Banner from '../Banner';
import { useData } from '../../../Context/DataContext';
import { onSnapshot } from 'firebase/firestore';
import PartidoComponente from '../../Componentes/PartidoComponente';
import Clasificacion from '../../Componentes/Clasificacion';
import AutoSizer from 'react-virtualized-auto-sizer';
import Texto from '../../Componentes/Texto';
import { RFPercentage } from '../../../utils/Functions';
import { useTema } from '../../../Context/TemaContext';

const calculateScale = (componentHeight) => {
  const windowHeight = window.innerHeight;
  return windowHeight / componentHeight;
};

export default function LigasMedios() {
  const classes = useStyles();

  const { ligas, buscarLiga } = useData();
  const { tema } = useTema();

  const [partidos, setPartidos] = useState([]);
  const [liga, setLiga] = useState(null);
  const [jornada, setJornada] = useState(null);
  const [jornadas, setJornadas] = useState([]);
  const [clasificacion, setClasificacion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [componentHeight, setComponentHeight] = useState(1000);

  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const suscribePartidos = async () => {
    const partidosRef = await getRef('partidos', [
      {
        type: 'where',
        param: 'liga',
        condition: '==',
        value: liga,
      },
      {
        type: 'where',
        param: 'jornada',
        condition: '==',
        value: jornada,
      },
    ]);

    const unsub = onSnapshot(partidosRef, (docsSnap) => {
      const partidosArray = [];

      docsSnap.forEach((doc) => {
        const data = doc.data();
        partidosArray.push({ ...data, id: doc.id });
      });

      setPartidos(partidosArray);
      setLoading(false);
    });
    return unsub;
  };

  useEffect(() => {
    suscribePartidos();
  }, [jornada, liga]);

  useEffect(() => {
    if (jornada === null && liga) {
      const l = buscarLiga(liga);
      setJornada(l.jornadaActual);
      setClasificacion(l.clasificacion);
      setComponentHeight(l.clasificacion.length * 92);
      let jor = [];
      for (let i = 0; i < l.jornadas; i++) {
        jor.push(i + 1);
      }
      setJornadas(jor);
      setTab(0);
    }
  }, [liga]);

  useEffect(() => {
    const newScale = calculateScale(componentHeight);
    setScale(newScale > 1.6 ? 1.6 : newScale);

    const handleResize = () => {
      const newScale = calculateScale(componentHeight);
      setScale(newScale > 1.6 ? 1.6 : newScale);
    };

    window.addEventListener('resize', handleResize);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [componentHeight]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Grid style={{ height: '100%' }} container>
          <Grid
            item
            xs={3}
            md={3}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Texto style={{ fontWeight: 'bold', padding: 10 }}>Ligas</Texto>
            <Box style={{ flex: 1 }}>
              <AutoSizer>
                {({ height, width }) => (
                  <Box
                    style={{
                      height: height,
                      maxHeight: height,
                      width: width,
                      overflowY: 'auto',
                    }}
                  >
                    {ligas
                      .sort((a, b) =>
                        a.orden > b.orden ? 1 : a.orden < b.orden ? -1 : 0
                      )
                      .map((item) => (
                        <Box
                          key={item.id}
                          onClick={() => {
                            setLiga(item.id);
                            setClasificacion(item.clasificacion);
                            setComponentHeight(item.clasificacion.length * 92);

                            let jor = [];
                            for (let i = 0; i < item.jornadas; i++) {
                              jor.push(i + 1);
                            }
                            setJornadas(jor);
                            setJornada(item.jornadaActual);
                            // setTab(0);
                          }}
                          style={{
                            backgroundColor:
                              liga === item.id
                                ? 'rgba(0, 0, 0, 0.05)'
                                : tema.backgroundColor,
                          }}
                        >
                          <Texto
                            style={{
                              padding: 10,
                              fontSize: RFPercentage(1.6),
                            }}
                          >
                            {item.nombre}
                          </Texto>
                        </Box>
                      ))}
                  </Box>
                )}
              </AutoSizer>
            </Box>
          </Grid>
          <Grid item xs={9} md={9} style={{ height: '100%' }}>
            {liga === null ? (
              <Box
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Texto>Seleccione una liga</Texto>
              </Box>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  backgroundColor: tema.backgroundColor,
                }}
              >
                <AppBar
                  position='static'
                  style={{ backgroundColor: '#33db7a', height: 50, zIndex: 1 }}
                >
                  <Tabs value={tab} onChange={handleChangeTab}>
                    <Tab label='Jornadas' />
                    <Tab label='Clasificación' />
                  </Tabs>
                </AppBar>
                <Box
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      minHeight: 50,
                    }}
                  >
                    {tab === 0 && (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          marginLeft: 12,
                        }}
                      >
                        <Box
                          style={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              variant='contained'
                              size={'small'}
                              style={{ backgroundColor: '#33db7a' }}
                              disabled={jornada === null || jornada === 1}
                              onClick={() => {
                                setJornada((prevState) => prevState - 1);
                              }}
                              startIcon={
                                <Icon>
                                  <ArrowBackIcon />
                                </Icon>
                              }
                            >
                              Anterior
                            </Button>
                            <FormControl className={classes.formControl}>
                              <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={jornada}
                                style={{
                                  width: 120,
                                  marginLeft: 60,
                                  marginRight: 60,
                                }}
                                MenuProps={{ width: 120 }}
                                onChange={(event) =>
                                  setJornada(event.target.value)
                                }
                              >
                                {jornadas.map((i) => (
                                  <MenuItem
                                    key={i}
                                    style={{ width: 120 }}
                                    value={i}
                                  >
                                    Jornada {i.toString()}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {/* <Typography
                              style={{
                                fontSize: 18,
                                fontWeight: '600',
                                marginLeft: 30,
                                marginRight: 30,
                              }}
                            >
                              Jornada {jornada}
                            </Typography> */}
                            <Button
                              variant='contained'
                              size={'small'}
                              style={{
                                backgroundColor: '#33db7a',
                              }}
                              disabled={
                                jornadas[jornadas.length - 1] === jornada
                              }
                              onClick={() => {
                                setJornada((prevState) => {
                                  if (prevState === null) {
                                    return 1;
                                  } else {
                                    return prevState + 1;
                                  }
                                });
                              }}
                              endIcon={
                                <Icon>
                                  <ArrowForwardIcon />
                                </Icon>
                              }
                            >
                              Siguiente
                            </Button>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </div>
                  {tab === 0 ? (
                    <Box style={{ flex: 1 }}>
                      <Box
                        style={{
                          paddingBottom: 10,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Texto
                          style={{
                            fontSize: 24,
                            fontWeight: '600',
                            textAlign: 'center',
                          }}
                        >
                          {liga}
                        </Texto>
                        <Texto
                          style={{
                            fontSize: 20,
                            fontWeight: '500',
                            textAlign: 'center',
                          }}
                        >
                          Jornada {jornada}
                        </Texto>
                      </Box>
                      {partidos &&
                        partidos.length > 0 &&
                        partidos
                          .sort((a, b) => {
                            if (a.fecha > b.fecha) {
                              return 1;
                            } else if (a.fecha < b.fecha) {
                              return -1;
                            } else {
                              if (a.hora > b.hora) {
                                return 1;
                              }
                              if (a.hora < b.hora) {
                                return -1;
                              }
                              return 0;
                            }
                          })
                          .map((partido) => (
                            <PartidoComponente
                              partido={partido}
                              key={partido.id}
                              scale={scale}
                            />
                          ))}
                    </Box>
                  ) : tab === 1 ? (
                    <Box
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Clasificacion
                        cargandoClasificacion={loading}
                        clasificacion={clasificacion}
                        ligaId={liga}
                        titulo={true}
                        scale={scale}
                      />
                    </Box>
                  ) : (
                    <Box
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    ></Box>
                  )}
                  <Banner />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      transform: 'scale(1.05)',
      '& .MuiSvgIcon-colorPrimary': {
        color: theme.palette.primary.contrastText,
      },
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: window.innerHeight - 150,
  },
  buttonDisabled: {
    borderRadius: '0.8rem',
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  icon: {
    '& svg': {
      fontSize: 150,
      height: '100%',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
  },
}));
