import React from 'react';
import { useTema } from '../../Context/TemaContext.js';
import { Box, ButtonBase, Collapse } from '@material-ui/core';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RFPercentage } from '../../utils/Functions.js';

const CollapseComponent = (props) => {
  const { children, style, title, icon } = props;

  const { tema } = useTema();

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box sx={{ width: '100%', ...style }}>
      <ButtonBase
        onClick={handleChange}
        style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
      >
        <Box sx={{ marginLeft: 12 }}>
          {checked ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
        </Box>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {icon && (
            <img
              src={icon}
              style={{
                height: RFPercentage(4),
                width: RFPercentage(4),
                marginRight: 12,
              }}
            />
          )}
          <h2 style={{ fontSize: RFPercentage(4), color: tema.texto }}>
            {title}
          </h2>
        </div>
      </ButtonBase>
      <Collapse in={checked}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapseComponent;
