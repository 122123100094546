import React, { useEffect, useState } from 'react';

const WordCloud = ({ words = [] }) => {
  const [randomWords, setRandomWords] = useState([]);

  useEffect(() => {
    const generateRandomWords = () => {
      return [...words, ...words, ...words, ...words, ...words].map((word) => {
        const size = Math.floor(Math.random() * 40) + 10; // Tamaño aleatorio entre 10 y 50 px
        const top = Math.floor(Math.random() * 100); // Posición top aleatoria
        const left = Math.floor(Math.random() * 100); // Posición left aleatoria
        const opacity = 0.1; // Opacidad fija

        return {
          word,
          style: {
            position: 'absolute',
            top: `${top}%`,
            left: `${left}%`,
            fontSize: `${size}px`,
            opacity: opacity,
            color: 'red', // Color de las palabras
            transform: `translate(-${top}%, -${left}%)`, // Para centrar correctamente
          },
        };
      });
    };

    setRandomWords(generateRandomWords());
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      {randomWords.map((item, index) => (
        <span key={index} style={item.style}>
          {item.word}
        </span>
      ))}
    </div>
  );
};

export default WordCloud;
