import React from 'react';
import './YouTubePlayer.css';
import { useEffect } from 'react';

const YouTubePlayer = ({ videoId }) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowRight':
          document
            .querySelector('iframe')
            .contentWindow.postMessage(
              '{"event":"command","func":"seekTo","args":[10,true]}',
              '*'
            );
          break;
        case 'ArrowLeft':
          document
            .querySelector('iframe')
            .contentWindow.postMessage(
              '{"event":"command","func":"seekTo","args":[-10,true]}',
              '*'
            );
          break;
        case ' ': // Space key for play/pause
        case 'Enter':
          document
            .querySelector('iframe')
            .contentWindow.postMessage(
              '{"event":"command","func":"playVideo","args":""}',
              '*'
            );
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  return (
    <iframe
      width='90%'
      height='65%'
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title='YouTube video player'
    ></iframe>
  );
};

export default YouTubePlayer;
