import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTema } from '../../../Context/TemaContext.js';
import { RFPercentage, youtubeParser } from '../../../utils/Functions.js';
import Texto from '../../Componentes/Texto.js';
import { useState, useEffect } from 'react';
import { getFilterData } from '../../../database/firebase.js';
import TVCarousel from './TVCarousel.js';
import YouTubePlayer from './YouTubePlayer.js';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NoticiasCarousel from './NoticiasCarousel.js';
import VideosCarousel from './VideosCarousel.js';

export default function TV() {
  const { tema } = useTema();

  const [selected, setSelected] = useState(0);
  const [video, setVideo] = useState(null);
  const [limit, setLimit] = useState(10);
  const [limitVideos, setLimitVideos] = useState(10);
  const [noticias, setNoticias] = useState([]);
  const [videos, setVideos] = useState([]);

  const [proximosPartidos, setProximosPartidos] = useState([]);

  const cargarVideos = async () => {
    const videosArray = await getFilterData('noticias', [
      {
        type: 'where',
        param: 'tipo',
        condition: '==',
        value: 'Video',
      },
      {
        type: 'orderBy',
        param: 'fecha',
        order: 'desc',
      },
      {
        type: 'limit',
        value: limitVideos,
      },
    ]);

    setVideos(videosArray);
  };

  const cargarNoticias = async () => {
    const noticiasArray = await getFilterData('noticias', [
      {
        type: 'where',
        param: 'tipo',
        condition: '==',
        value: 'Noticia',
      },
      {
        type: 'orderBy',
        param: 'fecha',
        order: 'desc',
      },
      {
        type: 'limit',
        value: limit,
      },
    ]);

    setNoticias(noticiasArray);
  };

  const cargarDatos = async () => {
    const partidosArray = await getFilterData('partidos', [
      {
        type: 'where',
        param: 'televisiones',
        condition: '>',
        value: [],
      },
      {
        type: 'where',
        param: 'estado',
        condition: 'in',
        value: ['Sin jugar'],
      },
    ]);

    const partidos = [];

    partidosArray.forEach((p) => {
      p.televisiones.forEach((i) => {
        partidos.push({
          ...p,
          enlace: i.enlace,
          televisión: i.televisión,
        });
      });
    });

    setProximosPartidos(
      partidos.sort((a, b) => {
        if (a.fecha > b.fecha) {
          return 1;
        } else if (a.fecha < b.fecha) {
          return -1;
        } else {
          if (a.hora > b.hora) {
            return 1;
          }
          if (a.hora < b.hora) {
            return -1;
          }
          return 0;
        }
      })
    );
  };

  useEffect(() => {
    cargarDatos();
  }, []);

  useEffect(() => {
    cargarNoticias();
  }, [limit]);

  useEffect(() => {
    cargarVideos();
  }, [limitVideos]);

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        backgroundColor: tema.backgroundColor,
      }}
    >
      {video ? (
        <Box
          style={{
            flex: 1,
            padding: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <Button
              onClick={() => {
                setVideo(null);
              }}
            >
              <ChevronLeftIcon style={{ fontSize: RFPercentage(10) }} />
            </Button>
            <Texto
              style={{
                flex: 1,
                textAlign: 'center',
                fontSize: RFPercentage(2.2),
                fontWeight: '600',
              }}
            >
              {video.titulo}
            </Texto>
          </Box>
          <YouTubePlayer videoId={youtubeParser(video.enlace)} />
        </Box>
      ) : (
        selected === 0 && (
          <Box style={{ flex: 1, padding: 24, height: '100%', width: '100%' }}>
            <Texto style={{ fontSize: RFPercentage(2.6), fontWeight: 'bold' }}>
              Próximos partidos
            </Texto>
            <TVCarousel partidos={proximosPartidos} setVideo={setVideo} />
            {videos.length > 0 && (
              <>
                <Texto
                  style={{ fontSize: RFPercentage(2.6), fontWeight: 'bold' }}
                >
                  Videos
                </Texto>
                <VideosCarousel videos={videos} setVideo={setVideo} />
              </>
            )}
            {/* {noticias.length > 0 && (
              <>
                <Texto
                  style={{ fontSize: RFPercentage(2.6), fontWeight: 'bold' }}
                >
                  Noticias
                </Texto>
                <NoticiasCarousel noticias={noticias} setVideo={setVideo} />
              </>
            )} */}
          </Box>
        )
      )}
    </Box>
  );
}
