import { initializeApp } from 'firebase/app';
import {
  collection,
  getFirestore,
  getDocs,
  where,
  doc,
  getDoc,
  orderBy,
  query,
  documentId,
  updateDoc,
  limit,
  addDoc,
  increment,
  setDoc,
  deleteDoc,
  Timestamp,
  arrayUnion,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import moment from 'moment';

var firebaseConfig = {
  apiKey: 'AIzaSyAU-t6_JVEG1O42uSnia9agX1Qb3-pdCBE',
  authDomain: 'app-bolos.firebaseapp.com',
  projectId: 'app-bolos',
  storageBucket: 'gs://app-bolos.appspot.com',
  messagingSenderId: '542284636729',
  appId: '1:542284636729:web:bb44e5d8f4435dc8a463e8',
  measurementId: 'G-SG3QE6Y1MS',
  databaseURL: 'https://app-bolos.firebaseio.com',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const auth = getAuth(app);

export const incrementValue = (value) => {
  return increment(value);
};

const incrementInfo = async (ref) => {
  if (
    ref === 'boleras' ||
    ref === 'copas' ||
    ref === 'jugadores' ||
    ref === 'ligas' ||
    ref === 'medios' ||
    ref === 'peñas' ||
    ref === 'sobres'
  ) {
    const increment = incrementValue(1);
    await updateItem('aplicación', 'información', {
      ...(ref === 'jugadores' && { versiónJugadores: increment }),
      ...(ref === 'boleras' && { versiónBoleras: increment }),
      ...(ref === 'copas' && { versiónCopas: increment }),
      ...(ref === 'ligas' && { versiónLigas: increment }),
      ...(ref === 'medios' && { versiónMedios: increment }),
      ...(ref === 'peñas' && { versiónPeñas: increment }),
      ...(ref === 'sobres' && { versiónSobres: increment }),
    });
  }
};

// READ
export const getFilterData = async (ref, filter = []) => {
  // console.log('filter', ref);
  let dbRef = collection(db, ref);

  if (filter.length > 0) {
    filter.forEach((f) => {
      if (f.type === 'where') {
        dbRef = query(dbRef, where(f.param, f.condition, f.value));
      } else if (f.type === 'orderBy') {
        dbRef = query(
          dbRef,
          orderBy(f.param, f.order !== undefined ? f.order : 'asc')
        );
      } else if (f.type === 'ids') {
        dbRef = query(dbRef, where(documentId(), f.condition, f.value));
      } else if (f.type === 'limit') {
        dbRef = query(dbRef, limit(f.value));
      }
    });
  }

  const result = await getDocs(dbRef);
  const newResult = [];
  result.forEach((doc) => {
    const data = doc.data();
    newResult.push({ ...data, id: doc.id });
  });
  return getArrayFromCollection(result);
};
export const getData = async (ref, id) => {
  // console.log('normal', ref);
  const docRef = doc(db, ref, id);
  const result = await getDoc(docRef);
  const data = result.data();
  return result.exists() ? { ...data, id: id } : null;
};

// CREATE
export const createItem = async (ref, value) => {
  const colRef = collection(db, ref);
  const data = await addDoc(colRef, {
    ...value,
    lastUpdate: moment(Timestamp.now().toDate()).format('YYYY-MM-DD HH:mm:ss'),
  });

  await incrementInfo(ref);

  return data.id;
};
export const createItemWithId = async (ref, id, value) => {
  const colRef = doc(db, ref, id);
  const data = await setDoc(colRef, {
    ...value,
    lastUpdate: moment(Timestamp.now().toDate()).format('YYYY-MM-DD HH:mm:ss'),
  });

  await incrementInfo(ref);

  return data;
};

// UPDATE
export const updateItem = async (ref, id, value, update = true) => {
  const docRef = doc(db, ref, id);
  await updateDoc(docRef, {
    ...value,
    lastUpdate: moment(Timestamp.now().toDate()).format('YYYY-MM-DD HH:mm:ss'),
  });

  if (update) {
    await incrementInfo(ref);
  }
};

// DELETE
export const deleteItem = async (ref, id) => {
  const docRef = doc(db, ref, id);
  await deleteDoc(docRef);

  if (
    ref === 'boleras' ||
    ref === 'copas' ||
    ref === 'jugadores' ||
    ref === 'ligas' ||
    ref === 'medios' ||
    ref === 'peñas'
  ) {
    await updateItem('aplicación', 'información', {
      eliminados: arrayUnion({
        ref: ref,
        id: id,
      }),
    });
  }
};

// REF
export const getRef = async (ref, filter, id = null) => {
  let dbRef = null;

  if (id !== null) {
    dbRef = doc(db, ref, id);
  } else {
    dbRef = collection(db, ref);

    if (filter.length > 0) {
      filter.forEach((f) => {
        if (f.type === 'where') {
          dbRef = query(dbRef, where(f.param, f.condition, f.value));
        } else if (f.type === 'orderBy') {
          dbRef = query(
            dbRef,
            orderBy(f.param, f.order !== undefined ? f.order : 'asc')
          );
        } else if (f.type === 'ids') {
          dbRef = query(dbRef, where(documentId(), f.condition, f.value));
        } else if (f.type === 'limit') {
          dbRef = query(dbRef, limit(f.value));
        }
      });
    }
  }

  return dbRef;
};

// STORAGE
export const uploadImage = async (url, blob) => {
  let newUrl = null;
  const storage = getStorage();
  const imageRef = ref(storage, url);
  await uploadBytes(imageRef, blob)
    .then(async () => {
      const data = await getDownloadURL(imageRef);
      newUrl = data;
    })
    .catch();

  return newUrl;
};

export const deleteImage = async (imageUrl) => {
  try {
    const storage = getStorage();

    const imageRef = ref(storage, imageUrl);

    await deleteImage(imageRef);
  } catch (error) {
    // console.log(error);
  }
};

const getArrayFromCollection = (collection) => {
  return collection.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
};

export default {
  db,
};
