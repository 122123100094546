import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { decryptData, encryptData } from '../utils/Functions';
import { enqueueSnackbar } from 'notistack';

const initialState = {
  alerta: {
    estado: false,
    status: 'error', //error, info, success, warning
    titulo: 'Error',
    descripcion: 'Ha ocurrido un error',
  },
  filtro: {
    enDirecto: false,
    televisado: false,
    modalidades: [],
    ligas: [],
    peñas: [],
    concursos: [],
    jugadores: [],
    tipoConcurso: [],
  },
  confirmación: {
    visible: false,
    aceptar: () => console.log('ok'),
    título: 'Título',
    descripción: 'Descripción',
    títuloBotón: 'Aceptar',
    cuentaAtrás: true,
  },
  bottomSheet: null,
  modalVisible: null,
  anuncianteTest: null,
};

const configReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'guardar-alerta':
      return {
        ...state,
        alerta: payload.data,
      };
    case 'guardar-anuncianteTest':
      return {
        ...state,
        anuncianteTest: payload.data,
      };
    case 'guardar-filtro':
      return {
        ...state,
        filtro: payload.data,
      };
    case 'guardar-confirmación':
      return {
        ...state,
        confirmación: payload.data,
      };
    case 'guardar-bottomSheet':
      return {
        ...state,
        bottomSheet: payload.data,
      };
    case 'guardar-modalVisible':
      return {
        ...state,
        modalVisible: payload.data,
      };
    default:
      return state;
  }
};

const ConfigContext = createContext(initialState);

const ConfigState = (props) => {
  const [state, dispatch] = useReducer(configReducer, initialState);

  const activarAlerta = async (datos) => {
    enqueueSnackbar(datos.titulo, {
      autoHideDuration: 6000,
      variant: datos.status,
    }); // dispatch({
    //   type: 'guardar-alerta',
    //   data: datos,
    // });

    // setTimeout(function () {
    //   dispatch({
    //     type: 'guardar-alerta',
    //     data: { ...datos, estado: false },
    //   });
    // }, 6000);
  };

  const setAnuncianteTest = async (datos) => {
    dispatch({
      type: 'guardar-anuncianteTest',
      data: datos,
    });
  };

  const cerrarAlerta = async () => {
    dispatch({
      type: 'guardar-alerta',
      data: {
        ...state.alerta,
        estado: false,
      },
    });
  };

  const setConfirmación = (datos) => {
    dispatch({
      type: 'guardar-confirmación',
      data: datos,
    });
  };

  const setBottomSheet = (datos) => {
    dispatch({
      type: 'guardar-bottomSheet',
      data: datos,
    });
  };

  const setModalVisible = (datos) => {
    dispatch({
      type: 'guardar-modalVisible',
      data: datos,
    });
  };

  const guardarFiltro = async (datos) => {
    try {
      await localStorage.setItem('Filtro', encryptData(datos));
      dispatch({
        type: 'guardar-filtro',
        data: datos,
      });
    } catch (error) {}
  };

  const cargarDatosIniciales = async () => {
    try {
      const fav = await localStorage.getItem('Filtro');
      if (fav !== null) {
        dispatch({ type: 'guardar-filtro', data: decryptData(fav) });
      }
    } catch (error) {}
  };

  useEffect(() => {
    cargarDatosIniciales();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        filtro: state.filtro,
        confirmación: state.confirmación,
        alerta: state.alerta,
        bottomSheet: state.bottomSheet,
        modalVisible: state.modalVisible,
        anuncianteTest: state.anuncianteTest,
        activarAlerta,
        cerrarAlerta,
        guardarFiltro,
        setConfirmación,
        setBottomSheet,
        setModalVisible,
        setAnuncianteTest,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

const useConfig = () => {
  return useContext(ConfigContext);
};

export { ConfigState, ConfigContext, useConfig };
