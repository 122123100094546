import React from 'react';
import { Box } from '@material-ui/core';
import Texto from '../Componentes/Texto';

export default function Banner() {
  return (
    <Box
      style={{
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginBottom: 6,
      }}
    >
      <img
        style={{
          width: 90,
          height: 90,
          alignSelf: 'center',
          borderRadius: 16,
        }}
        alt={'logo'}
        src={require('../../assets/icono.png')}
      />
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Texto
          style={{
            fontSize: 50,
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Resultados de Bolos
        </Texto>
      </Box>
      <img
        style={{
          width: 90,
          height: 90,
          alignSelf: 'center',
          borderRadius: 16,
        }}
        alt={'logo'}
        src={require('../../assets/qr-code.png')}
      />
    </Box>
  );
}
