export default function requireTvCard(nombre) {
  if (nombre === 'Popular TV') {
    return require('../assets/tv/popularTvCard.png');
  } else if (nombre === 'SportPublic TV') {
    return require('../assets/tv/sportpublicTvCard.png');
  } else if (nombre === 'Onda Occidental Cantabria') {
    return require('../assets/tv/ondaoccidentalTvCard.png');
  } else if (nombre === 'Facebook') {
    return require('../assets/tv/facebookCard.jpg');
  } else if (nombre === 'El Carru Leña') {
    return require('../assets/tv/carruCard.jpg');
  } else if (nombre === 'Radio Foramontanos') {
    return require('../assets/tv/foramontanosCard.jpg');
  } else if (nombre === 'Radio Studio') {
    return require('../assets/tv/radioStudioCard.jpg');
  } else if (nombre === 'CANTABRIALIVESTREAM') {
    return require('../assets/tv/cantabrialivestreamCard.jpg');
  } else if (nombre === 'Simancas Sport') {
    return require('../assets/tv/simancasCard.jpg');
  } else {
    return null;
  }
}
