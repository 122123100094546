import React, { useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useUsuario } from '../../Context/UsuarioContext';
import { WebScreen } from './WebScreen.js';
import { Aplicacion } from './Aplicacion.js';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../Router/routes.js';
import { useTema } from '../../Context/TemaContext.js';
import { Header } from './Header';
import LigasMedios from './Medios/LigasMedios.js';
import Medios from './Medios/Medios.js';
import Tienda from './Tienda.js';
import TV from './TV/TV.js';
import { useData } from '../../Context/DataContext.js';
import { useConfig } from '../../Context/ConfigContext.js';
import Ligas from './Competiciones/Ligas/Ligas.js';
import Copas from './Competiciones/Copas/Copas.js';
import Concursos from './Competiciones/Concursos/Concurso.js';
import NotFound from './NotFound.js';
import ModalidadesInfo from './Competiciones/ModalidadesInfo.js';
import { MapaBoleras } from './Boleras/MapaBoleras.js';
import Admin from './Admin/Admin.js';

export const Main = () => {
  const { tema } = useTema();
  const { setModalVisible } = useConfig();

  const location = useLocation();

  const [visibleUsuario, setVisibleUsuario] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  function redirectToMobileApp(url) {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent) || /android/i.test(userAgent)) {
      window.location.href = url;
      setTimeout(() => {
        setModalVisible({
          children: <Tienda />,
        });
      }, 2000);
    } else {
      setModalVisible({
        children: <Tienda />,
      });
    }
  }

  useEffect(() => {
    const isFromLink = location.state?.from;

    if (isFromLink) {
      if (
        location.pathname.includes('tutorial') ||
        location.pathname.includes('partido') ||
        location.pathname.includes('concurso') ||
        location.pathname.includes('qrLogin')
      ) {
        redirectToMobileApp(`resultados-de-bolos:/${location.pathname}`);
      }
    }
  }, [location]);

  const sinPubli = useMemo(() => {
    return (
      ROUTES.home === location.pathname ||
      ROUTES.modalidades === location.pathname
    );
  }, [location]);

  const Anuncio = () => {
    return <></>;
  };

  return (
    <Box
      w={1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: sinPubli ? tema.backgroundColor : tema.background,
        transition: 'background-color 0.5s ease, color 1s ease',
      }}
    >
      <Header
        visible={visible}
        setVisible={setVisible}
        visibleUsuario={visibleUsuario}
        setVisibleUsuario={setVisibleUsuario}
      />
      <Box
        sx={{
          flexDirection: { xs: 'column', md: 'column', lg: 'row' },
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {!sinPubli && (
          <Box
            sx={{
              height: '100%',
              width: '15%',
              display: { xs: 'none', md: 'none', lg: 'flex' },
            }}
          >
            <Anuncio />
          </Box>
        )}
        <Box
          sx={{
            maxWidth: {
              xs: '100vw',
              md: '100vw',
              lg: sinPubli ? '100vw' : 'Calc(100vw - 500px)',
            },
            flex: 1,
            display: 'flex',
            height: '100%',
          }}
        >
          <Routes>
            <Route path={ROUTES.home} element={<WebScreen />} />
            <Route path={ROUTES.inicio} element={<Aplicacion />} />
            <Route path={ROUTES.tv} element={<TV />} />
            <Route path={ROUTES.admin} element={<Admin />} />
            <Route path={ROUTES.medios} element={<Medios />} />
            <Route path={ROUTES.ligasMedios} element={<LigasMedios />} />
            <Route path={ROUTES.ligas} element={<Ligas />} />
            <Route path={ROUTES.copas} element={<Copas />} />
            <Route path={ROUTES.concursos} element={<Concursos />} />
            <Route path={ROUTES.campeonatos} element={<Concursos />} />
            <Route path={ROUTES.modalidades} element={<ModalidadesInfo />} />
            <Route path={ROUTES.boleras} element={<MapaBoleras />} />
            <Route path={ROUTES.error} element={<NotFound />} />
            <Route path={'/'} element={<Navigate to={ROUTES.home} />} />
          </Routes>
        </Box>
        {!sinPubli && (
          <Box
            sx={{
              height: { xs: '12vh', md: '12vh', lg: '100%' },
              width: { xs: '100%', md: '100%', lg: '15%' },
              maxHeight: { xs: '12vh', md: '12vh', lg: '100%' },
              maxWidth: { xs: '100%', md: '100%', lg: '15%' },
              display: 'flex',
            }}
          >
            <Anuncio />
          </Box>
        )}
      </Box>
    </Box>
  );
};
