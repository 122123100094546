export default function requireTv(nombre) {
  if (nombre === "Popular TV") {
    return require("../assets/tv/popularTv.png");
  } else if (nombre === "SportPublic TV") {
    return require("../assets/tv/sportpublicTv.png");
  } else if (nombre === "Onda Occidental Cantabria") {
    return require("../assets/tv/ondaoccidentalTv.png");
  } else if (nombre === "Facebook") {
    return require("../assets/tv/facebook.png");
  } else if (nombre === "El Carru Leña") {
    return require("../assets/tv/carru.jpg");
  } else if (nombre === "Radio Foramontanos") {
    return require("../assets/tv/foramontanos.jpg");
  } else if (nombre === "Radio Studio") {
    return require("../assets/tv/radioStudio.jpg");
  } else if (nombre === "CANTABRIALIVESTREAM") {
    return require("../assets/tv/cantabrialivestream.jpg");
  } else if (nombre === "Simancas Sport") {
    return require("../assets/tv/simancas.jpg");
  } else {
    return null;
  }
}
