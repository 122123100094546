import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { ROUTES } from '../../Router/routes.js';
import Icon from '@mdi/react';
import { mdiTrophy } from '@mdi/js';
import { mdiTrophyVariant } from '@mdi/js';
import { mdiTournament } from '@mdi/js';
import CrearLiga from '../Formularios/CrearLiga.js';

export default function Admin() {
  const classes = useStyles();

  const [selected, setSelected] = useState(0);

  const Boton = (props) => {
    const { disabled, onClick, icon, label } = props;

    return (
      <Paper
        className={disabled ? classes.buttonDisabled : classes.button}
        style={{ height: '100%', minHeight: '210px', borderRadius: 20 }}
      >
        <Box
          display='flex'
          style={{
            borderRadius: 20,
            height: '100%',
            width: '100%',
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.2)' : 'white',
          }}
        >
          <Button
            disabled={disabled}
            onClick={onClick}
            aria-label={label}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <Box
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box p={2}>{icon}</Box>
              <Typography className={classes.title} variant='h5'>
                {label}
              </Typography>
            </Box>
          </Button>
        </Box>
      </Paper>
    );
  };

  const botones = [
    {
      label: 'Crear Liga',
      onClick: () => {
        console.log('ok');
        setSelected(1);
      },
      icon: <Icon path={mdiTrophy} size={3} />,
      disabled: false,
    },
    {
      label: 'Crear Copa',
      onClick: () => setSelected(2),
      icon: <Icon path={mdiTrophyVariant} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Concurso',
      onClick: () => setSelected(3),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Final',
      onClick: () => setSelected(4),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
    {
      label: 'Crear Cameponato',
      onClick: () => setSelected(5),
      icon: <Icon path={mdiTournament} size={3} />,
      disabled: true,
    },
  ];

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
      }}
    >
      {selected === 0 && (
        <Box
          py={3}
          width={1}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid
            container
            direction='row'
            spacing={3}
            alignItems='stretch'
            justifyContent='center'
            style={{ height: '100%', maxHeight: '200px', minWidth: '1500px' }}
            role='menu'
          >
            {botones.map((item) => (
              <Grid key={item.label} item xs={2}>
                <Boton {...item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {selected === 1 && <CrearLiga />}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'black',
      transform: 'scale(1.05)',
      '& .MuiSvgIcon-colorPrimary': {
        color: 'black',
      },
    },
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  buttonDisabled: {
    borderRadius: '0.8rem',
    '& .MuiSvgIcon-colorPrimary': {
      color: '#000000',
    },
  },
  icon: {
    '& svg': {
      fontSize: 150,
      height: '100%',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
  },
}));
