import React from 'react';
import { RFPercentage } from '../../utils/Functions';
import WordCloud from '../Componentes/WordCloud';

const NotFound = () => {
  const frases = [
    'Caballo',
    'Blanca',
    'Chapa',
    'Coneja',
    'Al último',
    'Entre uno y dos',
    'Por fuera',
    'Nula',
  ];

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 2,
        }}
      >
        <h1 style={{ fontSize: RFPercentage(16), color: 'red' }}>Error 404</h1>
      </div>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 1,
        }}
      >
        <WordCloud words={frases} />
      </div>
    </div>
  );
};

export default NotFound;
