import React, { useState } from 'react';
import 'moment/locale/es';
import { useTema } from '../../../../../Context/TemaContext.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, IconButton } from '@mui/material';
import Texto from '../../../../Componentes/Texto.js';
import ConcursoComponente from '../../../../Componentes/ConcursoComponente.js';
import { RFPercentage } from '../../../../../utils/Functions.js';

const TiradasFinal = ({ concurso, tiradas }) => {
  const { tema } = useTema();

  const [ocultar, setOcultar] = useState([]);

  let ronda = '';

  return (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {tiradas
        .sort((a, b) => {
          if (a.fecha > b.fecha) {
            return 1;
          } else if (a.fecha < b.fecha) {
            return -1;
          } else {
            if (a.hora > b.hora) {
              return 1;
            }
            if (a.hora < b.hora) {
              return -1;
            }
            return 0;
          }
        })
        .map((tirada, index) => {
          let showRonda = false;
          if (ronda !== tirada.tipo) {
            ronda = tirada.tipo;
            showRonda = true;
          }
          return (
            <Box
              key={index}
              style={{ paddingVertical: showRonda ? 5 : 0, width: '100%' }}
            >
              {showRonda && (
                <Box
                  onClick={() =>
                    setOcultar((prevState) =>
                      prevState.includes(tirada.tipo)
                        ? prevState.filter((i) => i !== tirada.tipo)
                        : [...prevState, tirada.tipo]
                    )
                  }
                >
                  <Box
                    style={{
                      padding: 5,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    {/* <IconButton
                          name={
                            !ocultar.includes(tirada.tipo)
                              ? 'chevron-down'
                              : 'chevron-right'
                          }
                          type='material-community'
                          size={18}
                        /> */}
                    <Box style={{ width: 20 }} />

                    <Texto
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: RFPercentage(2.2),
                      }}
                    >
                      {tirada.tipo}
                    </Texto>
                    <Box style={{ width: 20 }} />
                  </Box>
                </Box>
              )}
              {!ocultar.includes(tirada.tipo) && (
                <ConcursoComponente
                  tirada={tirada}
                  concurso={concurso}
                  route={'Competiciones'}
                />
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default TiradasFinal;
