import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getFilterData, getRef } from '../database/firebase.js';
import moment from 'moment';
import { onSnapshot, Timestamp } from 'firebase/firestore';
import { decryptData, encryptData } from '../utils/Functions.js';
import { useUsuario } from './UsuarioContext.js';

const initialState = {
  jugadores: [],
  peñas: [],
  boleras: [],
  ligas: [],
  copas: [],
  concursos: [],
  categorias: [],
  información: null,
  datosLoading: true,
  anunciantes: [],
};

const dataReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'guardar-anunciantes':
      return {
        ...state,
        anunciantes: payload.data,
      };
    case 'guardar-jugadores':
      return {
        ...state,
        jugadores: payload.data,
      };
    case 'guardar-peñas':
      return {
        ...state,
        peñas: payload.data,
      };
    case 'guardar-boleras':
      return {
        ...state,
        boleras: payload.data,
      };
    case 'guardar-ligas':
      return {
        ...state,
        ligas: payload.data,
      };
    case 'guardar-copas':
      return {
        ...state,
        copas: payload.data,
      };
    case 'guardar-concursos':
      return {
        ...state,
        concursos: payload.data,
      };
    case 'guardar-categorias':
      return {
        ...state,
        categorias: payload.data,
      };
    case 'guardar-información':
      return {
        ...state,
        información: payload.data,
      };
    case 'guardar-loading':
      return {
        ...state,
        datosLoading: payload.data,
      };
    default:
      return state;
  }
};

const DataContext = createContext(initialState);

const DataState = (props) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  const { usuario } = useUsuario();

  const setDatosLoading = (datos) => {
    dispatch({
      type: 'guardar-loading',
      data: datos,
    });
  };

  const buscarPeña = (id) => {
    if (id === 'Licencia Individual') {
      return {
        nombre: 'Licencia Individual',
        id: 'Licencia Individual',
      };
    } else if (state.peñas && id !== '') {
      const peña = state.peñas.find((item) => item.id === id);
      return peña !== undefined
        ? peña
        : {
            nombre: '',
            id: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
      };
    }
  };

  const buscarJugador = (id) => {
    if (state.jugadores && id !== '') {
      const jugador = state.jugadores.find((item) => item.id === id);
      return jugador !== undefined
        ? jugador
        : {
            nombre: '',
            id: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
      };
    }
  };

  const buscarBolera = (id) => {
    if (state.boleras && id !== '') {
      const bolera = state.boleras.find((item) => item.id === id);
      return bolera !== undefined
        ? bolera
        : {
            nombre: '',
            id: '',
            enUso: false,
          };
    } else {
      return {
        nombre: '',
        id: '',
        enUso: false,
      };
    }
  };

  const buscarLiga = (id) => {
    if (state.ligas && id !== '') {
      const liga = state.ligas.find((item) => item.id === id);
      return liga !== undefined
        ? liga
        : {
            nombre: '',
            id: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
      };
    }
  };

  const buscarCopa = (id) => {
    if (state.copas && id !== '') {
      const copa = state.copas.find((item) => item.id === id);
      return copa !== undefined
        ? copa
        : {
            nombre: '',
            id: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
      };
    }
  };

  const buscarConcurso = (id) => {
    if (state.concursos && id !== '') {
      const concurso = state.concursos.find((item) => item.id === id);
      return concurso !== undefined
        ? concurso
        : {
            nombre: '',
            id: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
      };
    }
  };

  const buscarCategoria = (id) => {
    if (state.categorias && id !== '') {
      const categoria = state.categorias.find((item) => item.id === id);
      return categoria !== undefined
        ? categoria
        : {
            nombre: '',
            id: '',
            modalidad: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
        modalidad: '',
      };
    }
  };

  const categoriasFix = (cat) => {
    if (state.categorias && cat) {
      return cat
        .map((i) => state.categorias.find((j) => j.id === i)?.nombre)
        .join(', ');
    } else {
      return '';
    }
  };

  const buscarCompeticion = (id) => {
    if (state.ligas && state.copas && id !== '') {
      const data = state.ligas.concat(state.copas);
      const competicion = data.find((item) => item.id === id);
      return competicion !== undefined
        ? competicion
        : {
            nombre: '',
            id: '',
          };
    } else {
      return {
        nombre: '',
        id: '',
      };
    }
  };

  const cargarDatosIniciales = async () => {
    let datosLoading = {
      jugadores: true,
      boleras: true,
      peñas: true,
      ligas: true,
      copas: true,
      concursos: true,
      categorias: true,
      anunciantes: true,
    };

    try {
      const asyncTasks = [
        cargarDatos('Jugadores', 'guardar-jugadores', 'nombre'),
        cargarDatos('Boleras', 'guardar-boleras', 'nombre'),
        cargarDatos('Peñas', 'guardar-peñas', 'nombre'),
        cargarDatos('Ligas', 'guardar-ligas', 'orden'),
        cargarDatos('Copas', 'guardar-copas', 'nombre'),
        cargarDatos('Concursos', 'guardar-concursos', 'fecha'),
        cargarDatos('Categorias', 'guardar-categorias', 'orden'),
        cargarDatos('Anunciantes', 'guardar-anunciantes', 'nombre'),
      ];

      await Promise.all(asyncTasks);
    } catch (error) {
      console.error('Error cargando datos iniciales:', error);
    }

    if (Object.values(datosLoading).every((value) => value === false)) {
      setDatosLoading(false);
    }

    async function cargarDatos(key, dispatchType, sort) {
      const data = await localStorage.getItem(key);
      if (data !== null) {
        const parse = decryptData(data);
        dispatch({
          type: dispatchType,
          data: sort
            ? parse[key.toLowerCase()]
                .sort((a, b) => a[sort] - b[sort])
                .map((item) => {
                  let { dni, ...resto } = item;
                  return resto;
                })
            : parse[key.toLowerCase()].map((item) => {
                let { dni, ...resto } = item;
                return resto;
              }),
        });
        datosLoading[key.toLowerCase()] = parse[key.toLowerCase()].length === 0;
      } else {
        const dataArray = await getFilterData(key.toLowerCase(), [
          { type: 'orderBy', param: sort },
        ]);

        await localStorage.setItem(
          key,
          encryptData({
            ['versión' + key]: 0,
            [key.toLowerCase()]: dataArray.map((item) => {
              let { dni, ...resto } = item;
              return resto;
            }),
          })
        );
        dispatch({
          type: dispatchType,
          data: sort ? dataArray.sort((a, b) => a[sort] - b[sort]) : dataArray,
        });
        datosLoading[key.toLowerCase()] = dataArray.length === 0;
      }
    }
  };

  useEffect(() => {
    cargarDatosIniciales();
  }, []);

  const suscribeInformacion = async () => {
    const informacionRef = await getRef('aplicación', [], 'información');
    const now = moment(Timestamp.now().toDate())
      .subtract(2, 'hours')
      .format('YYYY-MM-DD HH:mm:ss');

    const unsub = onSnapshot(
      informacionRef,
      async (docsSnap) => {
        const informacion = docsSnap.data();

        dispatch({
          type: 'guardar-información',
          data: informacion,
        });

        const updateData = async (key, versionKey, ref, data, order) => {
          try {
            const storedData = await localStorage.getItem(key);
            if (storedData !== null) {
              const parsedData = decryptData(storedData);

              if (parsedData[versionKey] !== data[versionKey]) {
                const newData = await getFilterData(ref, [
                  {
                    type: 'where',
                    param: 'lastUpdate',
                    condition: '>',
                    value:
                      parsedData.lastUpdate !== undefined
                        ? parsedData.lastUpdate
                        : '2022-01-01 00:00:00',
                  },
                ]);

                const updatedData = parsedData[ref]
                  .map((oldItem) => {
                    const newItem = newData.find(
                      (newItem) => newItem.id === oldItem.id
                    );
                    return newItem ? newItem : oldItem;
                  })
                  .concat(
                    newData.filter(
                      (newItem) =>
                        !parsedData[ref].some(
                          (oldItem) => oldItem.id === newItem.id
                        )
                    )
                  );

                await localStorage.setItem(
                  key,
                  encryptData({
                    [versionKey]: data[versionKey],
                    [ref]: updatedData
                      .filter(
                        (i) =>
                          !data.eliminados.some(
                            (j) => j.ref === ref && j.id === i.id
                          )
                      )
                      .map((item) => {
                        let { dni, ...resto } = item;
                        return resto;
                      }),
                    lastUpdate: now,
                  })
                );
                dispatch({
                  type: `guardar-${ref}`,
                  data: updatedData
                    .filter(
                      (i) =>
                        !data.eliminados.some(
                          (j) => j.ref === ref && j.id === i.id
                        )
                    )
                    .map((item) => {
                      let { dni, ...resto } = item;
                      return resto;
                    }),
                });
              }
            } else {
              const newData = await getFilterData(ref, [
                { type: 'orderBy', param: order },
              ]);
              await localStorage.setItem(
                key,
                encryptData({
                  [versionKey]: data[versionKey],
                  [ref]: newData
                    .filter(
                      (i) =>
                        !data.eliminados.some(
                          (j) => j.ref === ref && j.id === i.id
                        )
                    )
                    .map((item) => {
                      let { dni, ...resto } = item;
                      return resto;
                    }),
                })
              );
              dispatch({
                type: `guardar-${ref}`,
                data: newData
                  .filter(
                    (i) =>
                      !data.eliminados.some(
                        (j) => j.ref === ref && j.id === i.id
                      )
                  )
                  .map((item) => {
                    let { dni, ...resto } = item;
                    return resto;
                  }),
              });
            }
          } catch (error) {
            console.error(`Error updating ${ref}:`, error);
          }
        };

        await Promise.all([
          updateData(
            'Anunciantes',
            'versiónAnunciantes',
            'anunciantes',
            informacion,
            'nombre'
          ),
          updateData(
            'Jugadores',
            'versiónJugadores',
            'jugadores',
            informacion,
            'nombre'
          ),
          updateData(
            'Boleras',
            'versiónBoleras',
            'boleras',
            informacion,
            'nombre'
          ),
          updateData('Peñas', 'versiónPeñas', 'peñas', informacion, 'nombre'),
          updateData('Ligas', 'versiónLigas', 'ligas', informacion, 'orden'),
          updateData('Copas', 'versiónCopas', 'copas', informacion, 'nombre'),
          updateData(
            'Concursos',
            'versiónConcursos',
            'concursos',
            informacion,
            'fecha'
          ),
          updateData(
            'Categorias',
            'versiónCategorias',
            'categorias',
            informacion,
            'orden'
          ),
        ]);
      },
      (error) => {
        if (error.code === 'unavailable') {
          setDatosLoading(true);
        } else {
          setDatosLoading(false);
        }
      }
    );

    return unsub;
  };

  useEffect(() => {
    suscribeInformacion();
  }, []);

  return (
    <DataContext.Provider
      value={{
        jugadores: state.jugadores,
        boleras: state.boleras,
        peñas: state.peñas,
        ligas: state.ligas,
        copas: state.copas,
        concursos: state.concursos,
        categorias: state.categorias,
        información: state.información,
        datosLoading: state.datosLoading,
        anunciantes: state.anunciantes,
        setDatosLoading,
        buscarPeña,
        buscarJugador,
        buscarBolera,
        buscarLiga,
        buscarCopa,
        buscarConcurso,
        buscarCompeticion,
        buscarCategoria,
        categoriasFix,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

const useData = () => {
  return useContext(DataContext);
};

export { DataState, DataContext, useData };
