import { Box } from '@material-ui/core';
import React from 'react';
import Texto from '../../../Componentes/Texto';
import { useTema } from '../../../../Context/TemaContext';
import { RFPercentage, youtubeParser } from '../../../../utils/Functions';
import requireTv from '../../../../database/requireTv';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Video = ({ item, indexSeleccionado, setIndexSeleccionado }) => {
  const { tema } = useTema();
  return (
    <Box
      key={item.id}
      style={{
        backgroundColor: tema.backgroundColor,
        width: '99%',
        alignSelf: 'center',
        marginBottom: 8,
        marginTop: 4,
        borderRadius: 10,
        borderBottomWidth: 1,
        borderBottomColor: tema.button.default.borderColor,
      }}
    >
      {indexSeleccionado === item.id ? (
        <iframe
          width='100%'
          height={RFPercentage(30)}
          src={`https://www.youtube.com/embed/${youtubeParser(
            item.enlace
          )}?si=eMsO12bj5I3BsbLa`}
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
          style={{ borderRadius: 12 }}
        ></iframe>
      ) : (
        <Box
          onClick={() => {
            if (indexSeleccionado === item.id) {
              setIndexSeleccionado(null);
            } else {
              setIndexSeleccionado(item.id);
            }
          }}
        >
          <Box
            style={{
              paddingHorizontal: 5,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <img
              src={`https://i.ytimg.com/vi/${youtubeParser(
                item.enlace
              )}/hqdefault.jpg`}
              style={{ height: 100, width: 180, borderRadius: 10 }}
              cachePolicy={'disk'}
            />
            <Box style={{ flex: 1, marginLeft: 12 }}>
              <Texto
                style={{
                  fontWeight: 'bold',
                  paddingHorizontal: 10,
                  fontSize: 16,
                }}
              >
                {item.titulo}
              </Texto>
              <Texto
                style={{
                  fontWeight: '300',
                  paddingHorizontal: 10,
                  fontSize: 12,
                }}
              >
                {item.creador === 'marcossaro' ? '' : item.creador}
              </Texto>
            </Box>
            {item.medio !== undefined && item.medio !== null ? (
              <img
                src={requireTv(item.medio)}
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 60,
                }}
              />
            ) : (
              <YouTubeIcon />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Video;
