import React, { useState } from 'react';
import { Dialog, DialogContent, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconoPeña from '../IconoPena.js';
import Texto from '../Texto.js';
import { useTema } from '../../../Context/TemaContext.js';
import { useData } from '../../../Context/DataContext.js';
import { CuadroDeTexto } from '../CuadroDeTexto.js';
import { FixedSizeList as List } from 'react-window';
import { responsiveHeight } from '../../../utils/Functions.js';

const PeñasModal = (props) => {
  const {
    visible,
    setVisible,
    actualizarPeña,
    filtro = null,
    vaciar = true,
  } = props;

  const { tema } = useTema();
  const { peñas } = useData();

  const peñasFiltradas = filtro
    ? peñas.filter((peña) => {
        for (const key in filtro) {
          if (peña[key] !== filtro[key]) {
            return false;
          }
        }
        return true;
      })
    : peñas;

  const [peñasFilter, setPeñasFilter] = useState(peñasFiltradas);
  const [search, setSearch] = useState('');

  const updateSearch = (text) => {
    const array = peñas.filter((item) => {
      const nombre = item.nombre
        .toUpperCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return nombre.includes(
        text
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      );
    });
    setPeñasFilter(array);
    setSearch(text);
  };

  const Row = ({ index, style }) => {
    const item = peñasFilter[index];
    return (
      <div
        key={item.id}
        style={{ ...style, cursor: 'pointer', padding: '10px 0' }}
        onClick={() => {
          actualizarPeña(item);
          setVisible(false);
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconoPeña peñaId={item.id} size={40} disabled />
          <div style={{ paddingLeft: '10px', flex: 1 }}>
            <Texto>{item.nombre}</Texto>
            <Texto style={{ fontSize: '0.8rem', fontWeight: '300' }}>
              {item.liga}
            </Texto>
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  return (
    <Dialog
      open={visible}
      onClose={() => setVisible(false)}
      maxWidth='sm'
      fullWidth
    >
      <DialogContent
        style={{
          backgroundColor: tema.backgroundColor,
          borderWidth: 1,
          borderColor: tema.button.default.borderColor,
          padding: '10px',
          height: '80vh',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1 }}>
            <CuadroDeTexto
              width={'100%'}
              placeholder='Buscar...'
              onChange={(text) => updateSearch(text)}
              value={search}
              debounceable
            />
          </div>
          <IconButton onClick={() => setVisible(false)}>
            <CloseIcon />
          </IconButton>
        </div>

        {vaciar && (
          <div
            style={{ cursor: 'pointer', padding: '10px 0' }}
            onClick={() => {
              actualizarPeña('');
              setVisible(false);
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px 0',
              }}
            >
              <IconoPeña peñaId={'Licencia Individual'} size={40} disabled />
              <Texto>Vaciar</Texto>
            </div>
            <Divider />
          </div>
        )}

        <List
          height={responsiveHeight(65)}
          itemCount={peñasFilter.length}
          itemSize={70}
          width={'100%'}
        >
          {Row}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default PeñasModal;
