import React from 'react';
import Texto from '../../../Componentes/Texto';
import { useTema } from '../../../../Context/TemaContext';
import { Box } from '@material-ui/core';

const Noticia = ({ item, indexSeleccionado, setIndexSeleccionado }) => {
  const { tema } = useTema();
  return (
    <Box
      key={item.id}
      style={{
        width: '96%',
        alignSelf: 'center',
        marginBottom: 8,
        marginTop: 4,
        borderBottomWidth: 1,
        borderBottomColor: tema.button.default.borderColor,
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              paddingBottom: 10,
            }}
          >
            {item.enlace && (
              <img
                src={item.enlace}
                style={{
                  height: 200,
                  width: '100%',
                  backgroundColor: 'transparent',
                  borderRadius: 10,
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          onClick={() => {
            if (indexSeleccionado === item.id) {
              setIndexSeleccionado(null);
            } else {
              setIndexSeleccionado(item.id);
            }
          }}
          activeOpacity={0}
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              width: '92%',
            }}
          >
            <Texto
              style={{
                fontWeight: 'bold',
                fontSize: 16,
              }}
            >
              {item.titulo}
            </Texto>
            {item.subtitulo && item.subtitulo !== '' && (
              <Texto
                style={{
                  fontWeight: '400',
                  fontSize: 14,
                  marginTop: 4,
                }}
              >
                {item.subtitulo}
              </Texto>
            )}
            <Texto
              style={{
                fontWeight: '300',
                fontSize: 12,
                marginTop: 4,
              }}
            >
              {`${item.creador} ${item.medio ? ' - ' : ''} ${
                item.medio ? item.medio : ''
              }`}
            </Texto>
            {indexSeleccionado === item.id && item.descripcion.length > 0 && (
              <Texto
                style={{
                  fontWeight: '300',
                  fontSize: 12,
                  paddingBottom: 20,
                  marginTop: 12,
                }}
              >
                {item.descripcion}
              </Texto>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Noticia;
