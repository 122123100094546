import React, { useState } from 'react';
import { useData } from '../../Context/DataContext.js';
import { Avatar, Box } from '@material-ui/core';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import IconoPeña from './IconoPena.js';
import { useMemo } from 'react';
import { useTema } from '../../Context/TemaContext.js';
import { RFPercentage } from '../../utils/Functions.js';

const IconoJugador = (props) => {
  const { jugadores } = useData();
  const { tema } = useTema();

  const {
    jugador,
    size = 'pequeño',
    peña = true,
    jugadoresArray = jugadores !== null && jugadores !== undefined
      ? jugadores
      : [],
    paddingHorizontal = 5,
    style = null,
    borderRadius = 60,
    scale = 1,
  } = props;

  const jugadorData = useMemo(
    () =>
      jugadoresArray.find((item) => item.id === jugador) !== undefined
        ? jugadoresArray.find((item) => item.id === jugador)
        : null,
    [jugadoresArray, jugador]
  );

  const [isLoaded, setIsLoaded] = useState(false);

  if (jugador === '') {
    return <Box style={{ width: 40 * scale }}></Box>;
  } else {
    return (
      <Box
        style={{
          ...style,
          paddingHorizontal: paddingHorizontal,
          position: 'relative',
          zIndex: 1,
          borderRadius: 60,
        }}
      >
        {jugadorData !== null && jugadorData.imagen !== undefined ? (
          <img
            alt={jugadorData.nombre}
            src={jugadorData.imagen}
            style={{
              height:
                size === 'grande'
                  ? 120 * scale
                  : size === 'mediano'
                  ? 50 * scale
                  : size === 'pequeño'
                  ? 36 * scale
                  : 18 * scale,
              width:
                size === 'grande'
                  ? 120 * scale
                  : size === 'mediano'
                  ? 50 * scale
                  : size === 'pequeño'
                  ? 36 * scale
                  : 18 * scale,
              borderRadius: borderRadius,
              boxShadow: '0 4px 10px -8px #000',
              opacity: isLoaded ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
            onLoad={() => setIsLoaded(true)}
          />
        ) : (
          <Avatar
            style={{
              height:
                size === 'grande'
                  ? 120 * scale
                  : size === 'mediano'
                  ? 50 * scale
                  : size === 'pequeño'
                  ? 36 * scale
                  : 18 * scale,
              width:
                size === 'grande'
                  ? 120 * scale
                  : size === 'mediano'
                  ? 50 * scale
                  : size === 'pequeño'
                  ? 36 * scale
                  : 18 * scale,
              backgroundColor: tema.backgroundColor,
              boxShadow: '0 4px 10px -8px #000',
            }}
          >
            <PermIdentityIcon
              sx={{
                color: tema.button.default.borderColor,
                transition: 'color 1s ease',
                fontSize: RFPercentage(4 * scale),
              }}
            />
          </Avatar>
        )}
        {jugadorData && peña && (
          <IconoPeña
            peñaId={jugadorData.peña}
            size={
              size === 'grande'
                ? 50 * scale
                : size === 'mediano'
                ? 20 * scale
                : size === 'pequeño'
                ? 20 * scale
                : 12 * scale
            }
            style={{
              position: 'absolute',
              zIndex: 10,
              bottom: 0,
              right: 0,
              // backgroundColor: 'white',
              // borderWidth: 1,
              // borderColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: 60,
            }}
          />
        )}
      </Box>
    );
  }
};

export default IconoJugador;
