import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getData } from '../database/firebase.js';
import { ConfigContext } from './ConfigContext.js';

const initialState = {
  usuario: {
    nombreUsuario: 'Anónimo',
    contraseña: '',
    permisos: [],
    puntos: 0,
    notificaciones: [],
    favorito: '',
    sobres: [],
    expoPushToken: '',
    registro: [],
    estado: 'Normal',
    favoritos: [],
    memoriaRecord: 100,
    peñasRecord: 0,
    bolerasRecord: 0,
    jugadoresRecord: 0,
    puzleRecord: {
      facil: 1000000,
      normal: 1000000,
      dificil: 1000000,
    },
    sopaDeLetrasRecord: {
      facil: 1000000,
      normal: 1000000,
      dificil: 1000000,
      extremo: 1000000,
    },
  },
  activo: false,
  loading: true,
};

// function guardarUsuario(usuarios, nuevoUsuario) {
//   const usuarioExistente = usuarios.find(
//     (usuario) => usuario.nombreUsuario === nuevoUsuario.nombreUsuario
//   );

//   if (!usuarioExistente && nuevoUsuario.nombreUsuario !== 'Anónimo') {
//     usuarios.push(nuevoUsuario);
//   }

//   return usuarios;
// }

const usuarioReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'sign':
      return {
        ...state,
        usuario: payload.data,
        activo: true,
        loading: false,
      };
    case 'sign-out':
      return payload.data;
    case 'loading':
      return { ...state, loading: payload.data };
    default:
      return state;
  }
};

const UsuarioContext = createContext(initialState);

const UserState = ({ children }) => {
  const [state, dispatch] = useReducer(usuarioReducer, initialState);

  const { activarAlerta } = useContext(ConfigContext);

  const getUser = async (nombre, contraseña, expoPushToken = undefined) => {
    const data = await getData('usuarios', nombre);
    if (data === null) {
      try {
        activarAlerta({
          estado: true,
          status: 'error',
          titulo: 'Nombre de usuario incorrecto',
          descripcion: '',
        });
      } catch (error) {}
    } else if (data.contraseña !== contraseña) {
      try {
        activarAlerta({
          estado: true,
          status: 'error',
          titulo: 'Contraseña incorrecta',
          descripcion: '',
        });
      } catch (error) {}
    } else if (data.permisosWeb === undefined || data.permisosWeb === false) {
      try {
        activarAlerta({
          estado: true,
          status: 'warning',
          titulo: 'No tienes permisos para entrar a la web.',
          descripcion: '',
        });
      } catch (error) {}
    } else {
      await localStorage.setItem('ID', nombre);

      dispatch({ type: 'sign', data: data });
    }
  };

  const getUserQR = async (nombre) => {
    const data = await getData('usuarios', nombre);
    if (data === null) {
      activarAlerta({
        estado: true,
        status: 'error',
        titulo: 'Error al iniciar sesión',
        descripcion: '',
      });
    } else {
      await localStorage.setItem('ID', nombre);

      dispatch({ type: 'sign', data: data });
    }
  };

  // const anonimo = async () => {
  //   let memoriaRecord = 100;
  //   let peñasRecord = 0;
  //   let bolerasRecord = 0;
  //   let jugadoresRecord = 0;
  //   let puzleRecord = {
  //     facil: 1000000,
  //     normal: 1000000,
  //     dificil: 1000000,
  //   };
  //   let sopaDeLetrasRecord = {
  //     facil: 1000000,
  //     normal: 1000000,
  //     dificil: 1000000,
  //     extremo: 1000000,
  //   };

  //   try {
  //     let newRecord = await localStorage.getItem('memoriaRecord');
  //     memoriaRecord = newRecord ? parseInt(newRecord) : 100;
  //   } catch (error) {}

  //   try {
  //     let newRecord = await localStorage.getItem('peñasRecord');
  //     peñasRecord = newRecord ? parseInt(newRecord) : 0;
  //   } catch (error) {}

  //   try {
  //     let newRecord = await localStorage.getItem('bolerasRecord');
  //     bolerasRecord = newRecord ? parseInt(newRecord) : 0;
  //   } catch (error) {}

  //   try {
  //     let newRecord = await localStorage.getItem('jugadoresRecord');
  //     jugadoresRecord = newRecord ? parseInt(newRecord) : 0;
  //   } catch (error) {}

  //   try {
  //     let newRecord = await localStorage.getItem('puzleRecord');
  //     puzleRecord = newRecord
  //       ? JSON.parse(newRecord)
  //       : {
  //           facil: 1000000,
  //           normal: 1000000,
  //           dificil: 1000000,
  //         };
  //   } catch (error) {}

  //   try {
  //     let newRecord = await localStorage.getItem('sopaDeLetrasRecord');
  //     sopaDeLetrasRecord = newRecord
  //       ? JSON.parse(newRecord)
  //       : {
  //           facil: 1000000,
  //           normal: 1000000,
  //           dificil: 1000000,
  //           extremo: 1000000,
  //         };
  //   } catch (error) {}

  //   const pruebaState = {
  //     nombreUsuario: 'Anónimo',
  //     contraseña: '',
  //     permisos: [],
  //     puntos: 0,
  //     notificaciones: [],
  //     favorito: '',
  //     sobres: [],
  //     expoPushToken: '',
  //     registro: [],
  //     estado: 'Normal',
  //     favoritos: [],
  //     memoriaRecord: memoriaRecord,
  //     peñasRecord: peñasRecord,
  //     bolerasRecord: bolerasRecord,
  //     jugadoresRecord: jugadoresRecord,
  //     puzleRecord: puzleRecord,
  //     sopaDeLetrasRecord: sopaDeLetrasRecord,
  //   };
  //   dispatch({ type: 'sign', data: pruebaState });
  // };

  const chargeUser = async (datos) => {
    dispatch({ type: 'sign', data: datos });
  };

  const chargeBans = async (datos) => {
    dispatch({ type: 'bans', data: datos });
  };

  const deleteUser = async () => {
    try {
      await localStorage.removeItem('ID');
      dispatch({ type: 'sign-out', data: initialState });
    } catch (error) {
      console.log(error);
    }
  };

  async function cargarUsuario(id) {
    const data = await getData('usuarios', id);
    chargeUser(data);
  }

  async function callId() {
    try {
      const i = await localStorage.getItem('ID');
      if (i !== null && i !== 'anonimo' && i !== 'Anónimo') {
        try {
          const data = await getData('usuarios', i);
          chargeUser(data);
        } catch (error) {
          dispatch({ type: 'loading', data: false });
        }
      } else {
        // anonimo();
        dispatch({ type: 'loading', data: false });
      }
    } catch (error) {
      dispatch({ type: 'loading', data: false });
    }
  }

  useEffect(() => {
    callId();
  }, [state.activo]);

  return (
    <UsuarioContext.Provider
      value={{
        usuario: state.usuario,
        activo: state.activo,
        userLoading: state.loading,
        getUser,
        chargeUser,
        chargeBans,
        deleteUser,
        cargarUsuario,
        getUserQR,
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
};

const useUsuario = () => {
  return useContext(UsuarioContext);
};

export { UserState, UsuarioContext, useUsuario };
