import React from 'react';
import IconoJugador from './IconoJugador.js';
import IconoPeña from './IconoPena.js';
import Texto from './Texto.js';
import { Box } from '@material-ui/core';
import { useData } from '../../Context/DataContext.js';
import { useTema } from '../../Context/TemaContext.js';
import {
  RFPercentage,
  innerHeight,
  innerWidth,
} from '../../utils/Functions.js';

const Ronda = (props) => {
  const {
    ronda,
    tiradas = null,
    partidos = null,
    nRondas,
    scale = 0.5,
    copa = null,
    concurso = null,
    habilitado = true,
  } = props;

  const { buscarPeña, buscarJugador } = useData();

  const { tema } = useTema();

  const width = innerWidth > 600 ? innerHeight * 0.5 : innerWidth * 0.7;

  const LlavePequeña = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          style={{
            width: width * 0.1,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: ronda.ronda === 'Cuartos' ? 85 : 60,
          }}
        >
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 50,
            }}
          />
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 110,
            }}
          />
        </Box>
        <Box
          style={{
            width: 2,
            height: 114,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 50,
          }}
        />
        <Box
          style={{
            width: innerWidth * 0.05,
            height: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 105,
          }}
        />
      </Box>
    );
  };

  const LlaveMediana = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          style={{
            width: width * 0.1,
            display: 'flex',
            flexDirection: 'column',
            marginBottom:
              ronda.ronda === 'Cuartos' || ronda.ronda === 'Octavos' ? 125 : 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 100,
            }}
          />
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 215,
            }}
          />
        </Box>
        <Box
          style={{
            width: 2,
            height: 219,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 100,
          }}
        />
        <Box
          style={{
            width: innerWidth * 0.05,
            height: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 210,
          }}
        />
      </Box>
    );
  };

  const LlaveGrande = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          style={{
            width: width * 0.1,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: ronda.ronda === 'Cuartos' ? 240 : 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 205,
            }}
          />
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 440,
            }}
          />
        </Box>
        <Box
          style={{
            width: 2,
            height: 444,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 205,
          }}
        />
        <Box
          style={{
            width: innerWidth * 0.05,
            height: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 420,
          }}
        />
      </Box>
    );
  };

  const LlaveGigante = () => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          style={{
            width: width * 0.1,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: ronda.ronda === 'Cuartos' ? 65 : 0,
          }}
        >
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 320,
            }}
          />
          <Box
            style={{
              width: '100%',
              height: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              marginTop: 985,
            }}
          />
        </Box>
        <Box
          style={{
            width: 2,
            height: 989,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 320,
          }}
        />
        <Box
          style={{
            width: innerWidth * 0.05,
            height: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            marginTop: 890,
          }}
        />
      </Box>
    );
  };

  return (
    <Box style={{ flex: 1 }}>
      {tiradas === null ? (
        <>
          {nRondas === 1 && (
            <Box
              style={{
                flex: 1,
                width: width * scale,
                padding: 5,
                display: 'flex',
                flexDirection: 'row',

                marginRight: ronda.ronda === 'Final' ? 100 : 0,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop: 0,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const partido = partidos.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Box style={{ flex: 1 }}>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box
                                  style={{ paddingBottom: 5, paddingTop: 5 }}
                                >
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.local}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.local)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box style={{ paddingBottom: 5 }}>
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.visitante}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.visitante)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 30,
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_l : 0}
                              </Texto>
                            </Box>
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_v : 0}
                              </Texto>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
          {nRondas === 2 && (
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: ronda.ronda === 'Semifinales' ? 0 : 50,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const partido = partidos.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: ronda.ronda === 'Semifinales' ? 10 : 0,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Box style={{ flex: 1 }}>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box
                                  style={{ paddingBottom: 5, paddingTop: 5 }}
                                >
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.local}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.local)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box style={{ paddingBottom: 5 }}>
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.visitante}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.visitante)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 30,
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_l : 0}
                              </Texto>
                            </Box>
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_v : 0}
                              </Texto>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                </Box>
              )}
            </Box>
          )}
          {nRondas === 3 && (
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop:
                    ronda.ronda === 'Cuartos'
                      ? 0
                      : ronda.ronda === 'Semifinales'
                      ? 50
                      : 160,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const partido = partidos.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom:
                          ronda.ronda === 'Cuartos'
                            ? 10
                            : ronda.ronda === 'Semifinales'
                            ? 120
                            : 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Box style={{ flex: 1 }}>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box
                                  style={{ paddingBottom: 5, paddingTop: 5 }}
                                >
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.local}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.local)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box style={{ paddingBottom: 5 }}>
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.visitante}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.visitante)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 30,
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_l : 0}
                              </Texto>
                            </Box>
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_v : 0}
                              </Texto>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Cuartos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                  <LlavePequeña />
                </Box>
              )}
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveMediana />
                </Box>
              )}
            </Box>
          )}
          {nRondas === 4 && (
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop:
                    ronda.ronda === 'Octavos'
                      ? 0
                      : ronda.ronda === 'Cuartos'
                      ? 50
                      : ronda.ronda === 'Semifinales'
                      ? 160
                      : 375,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const partido = partidos.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom:
                          ronda.ronda === 'Octavos'
                            ? 10
                            : ronda.ronda === 'Cuartos'
                            ? 120
                            : ronda.ronda === 'Semifinales'
                            ? 340
                            : 0,
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Box style={{ flex: 1 }}>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box
                                  style={{ paddingBottom: 5, paddingTop: 5 }}
                                >
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.local}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.local)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box style={{ paddingBottom: 5 }}>
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.visitante}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.visitante)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 30,
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_l : 0}
                              </Texto>
                            </Box>
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_v : 0}
                              </Texto>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Octavos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                </Box>
              )}
              {ronda.ronda === 'Cuartos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveMediana />
                  <Box style={{ height: 55 }} />
                  <LlaveMediana />
                </Box>
              )}
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveGrande />
                </Box>
              )}
            </Box>
          )}
          {nRondas === 5 && (
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop:
                    ronda.ronda === 'Dieciseisavos'
                      ? 0
                      : ronda.ronda === 'Octavos'
                      ? 50
                      : ronda.ronda === 'Cuartos'
                      ? 160
                      : ronda.ronda === 'Semifinales'
                      ? 280
                      : 840,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const partido = partidos.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom:
                          ronda.ronda === 'Dieciseisavos'
                            ? 10
                            : ronda.ronda === 'Octavos'
                            ? 120
                            : ronda.ronda === 'Cuartos'
                            ? 340
                            : ronda.ronda === 'Semifinales'
                            ? 880
                            : 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Box style={{ flex: 1 }}>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box
                                  style={{ paddingBottom: 5, paddingTop: 5 }}
                                >
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.local}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.local)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                height: 50,
                              }}
                            >
                              <Box
                                style={{
                                  paddingRight: 10,
                                  paddingLeft: 10,
                                  width: 70,
                                }}
                              >
                                <Box style={{ paddingBottom: 5 }}>
                                  {partido !== undefined && (
                                    <Box
                                      style={{
                                        borderRadius: 60,
                                        backgroundColor: tema.backgroundColor,
                                        width: 40,
                                      }}
                                    >
                                      <IconoPeña
                                        peñaId={partido.visitante}
                                        size={40}
                                        disabled
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'flex-start',
                                  flex: 1,
                                  marginLeft: 0,
                                }}
                              >
                                <Texto
                                  numberOfLines={1}
                                  style={styles.instructions}
                                >
                                  {partido !== undefined
                                    ? buscarPeña(partido.visitante)?.nombre
                                    : ''}
                                </Texto>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: 30,
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_l : 0}
                              </Texto>
                            </Box>
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Texto
                                style={{
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  color: 'white',
                                }}
                              >
                                {partido !== undefined ? partido.ch_v : 0}
                              </Texto>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Dieciseisavos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                  <LlavePequeña />
                  <LlavePequeña />
                  <LlavePequeña />
                  <LlavePequeña />
                  <LlavePequeña />
                  <LlavePequeña />
                  <LlavePequeña />
                </Box>
              )}
              {ronda.ronda === 'Octavos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10,
                  }}
                >
                  <LlaveMediana />
                  <LlaveMediana />
                  <LlaveMediana />
                  <LlaveMediana />
                </Box>
              )}
              {ronda.ronda === 'Cuartos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10,
                  }}
                >
                  <LlaveGrande />
                  <LlaveGrande />
                </Box>
              )}
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 10,
                  }}
                >
                  <LlaveGigante />
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <>
          {nRondas === 1 && (
            <Box
              style={{
                flex: 1,
                width: width * scale,
                padding: 5,
                display: 'flex',
                flexDirection: 'row',

                marginRight: ronda.ronda === 'Final' ? 100 : 0,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop: 0,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const tirada = tiradas.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {tirada !== undefined ? (
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Box style={{ flex: 1 }}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador1
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === true ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador1 !== undefined
                                        ? buscarJugador(tirada.jugador1)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores1[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores1[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador2
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft: 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador2 !== undefined
                                        ? buscarJugador(tirada.jugador2)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores2[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores2[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  width: 0.5,
                                  backgroundColor: 'grey',
                                  height: '100%',
                                }}
                              />
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 60,
                                }}
                              >
                                <Box style={{ paddingTop: 10 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos1 : 0}
                                  </Texto>
                                </Box>
                                <Box style={{ paddingTop: 20 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos2 : 0}
                                  </Texto>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
          {nRondas === 2 && (
            <Box
              style={{
                flex: 1,
                width: width * scale,
                padding: 5,
                display: 'flex',
                flexDirection: 'row',

                marginRight: ronda.ronda === 'Final' ? 100 : 0,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop: ronda.ronda === 'Semifinales' ? 0 : 50,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const tirada = tiradas.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: ronda.ronda === 'Semifinales' ? 10 : 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {tirada !== undefined ? (
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Box style={{ flex: 1 }}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador1
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador1 !== undefined
                                        ? buscarJugador(tirada.jugador1)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores1[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores1[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador2
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador2 !== undefined
                                        ? buscarJugador(tirada.jugador2)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores2[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores2[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  width: 0.5,
                                  backgroundColor: 'grey',
                                  height: '100%',
                                }}
                              />
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 60,
                                }}
                              >
                                <Box style={{ paddingTop: 10 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos1 : 0}
                                  </Texto>
                                </Box>
                                <Box style={{ paddingTop: 20 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos2 : 0}
                                  </Texto>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                </Box>
              )}
            </Box>
          )}
          {nRondas === 3 && (
            <Box
              style={{
                flex: 1,
                width: width * scale,
                padding: 5,
                display: 'flex',
                flexDirection: 'row',

                marginRight: ronda.ronda === 'Final' ? 100 : 0,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop:
                    ronda.ronda === 'Cuartos'
                      ? 0
                      : ronda.ronda === 'Semifinales'
                      ? 50
                      : 160,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const tirada = tiradas.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom:
                          ronda.ronda === 'Cuartos'
                            ? 10
                            : ronda.ronda === 'Semifinales'
                            ? 120
                            : 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {tirada !== undefined ? (
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Box style={{ flex: 1 }}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador1
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador1 !== undefined
                                        ? buscarJugador(tirada.jugador1)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores1[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores1[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador2
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador2 !== undefined
                                        ? buscarJugador(tirada.jugador2)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores2[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores2[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  width: 0.5,
                                  backgroundColor: 'grey',
                                  height: '100%',
                                }}
                              />
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 60,
                                }}
                              >
                                <Box style={{ paddingTop: 10 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos1 : 0}
                                  </Texto>
                                </Box>
                                <Box style={{ paddingTop: 20 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos2 : 0}
                                  </Texto>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Cuartos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                  <LlavePequeña />
                </Box>
              )}
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveMediana />
                </Box>
              )}
            </Box>
          )}
          {nRondas === 4 && (
            <Box
              style={{
                flex: 1,
                width: width * scale,
                padding: 5,
                display: 'flex',
                flexDirection: 'row',

                marginRight: ronda.ronda === 'Final' ? 100 : 0,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop:
                    ronda.ronda === 'Octavos'
                      ? 0
                      : ronda.ronda === 'Cuartos'
                      ? 50
                      : ronda.ronda === 'Semifinales'
                      ? 160
                      : 375,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const tirada = tiradas.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom:
                          ronda.ronda === 'Octavos'
                            ? 10
                            : ronda.ronda === 'Cuartos'
                            ? 120
                            : ronda.ronda === 'Semifinales'
                            ? 340
                            : 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {tirada !== undefined ? (
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Box style={{ flex: 1 }}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador1
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador1 !== undefined
                                        ? buscarJugador(tirada.jugador1)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores1[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores1[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador2
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador2 !== undefined
                                        ? buscarJugador(tirada.jugador2)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores2[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores2[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  width: 0.5,
                                  backgroundColor: 'grey',
                                  height: '100%',
                                }}
                              />
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 60,
                                }}
                              >
                                <Box style={{ paddingTop: 10 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos1 : 0}
                                  </Texto>
                                </Box>
                                <Box style={{ paddingTop: 20 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos2 : 0}
                                  </Texto>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Octavos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                </Box>
              )}
              {ronda.ronda === 'Cuartos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveMediana />
                  <Box style={{ height: 55 }} />
                  <LlaveMediana />
                </Box>
              )}
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveGrande />
                </Box>
              )}
            </Box>
          )}
          {nRondas === 5 && (
            <Box
              style={{
                flex: 1,
                width: width * scale,
                padding: 5,
                display: 'flex',
                flexDirection: 'row',
                marginRight: ronda.ronda === 'Final' ? 100 : 0,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: width,
                  marginTop:
                    ronda.ronda === 'Dieciseisavos'
                      ? 0
                      : ronda.ronda === 'Octavos'
                      ? 50
                      : ronda.ronda === 'Cuartos'
                      ? 160
                      : ronda.ronda === 'Semifinales'
                      ? 375
                      : 840,
                }}
              >
                {ronda.ids.map((id, index) => {
                  const tirada = tiradas.find((item) => item.id === id);
                  return (
                    <Box
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom:
                          ronda.ronda === 'Dieciseisavos'
                            ? 10
                            : ronda.ronda === 'Octavos'
                            ? 120
                            : ronda.ronda === 'Cuartos'
                            ? 340
                            : ronda.ronda === 'Semifinales'
                            ? 880
                            : 0,
                      }}
                    >
                      <Box
                        style={{
                          width: width,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          style={{
                            width: width,
                            borderRadius: 6,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            height: 100,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {tirada !== undefined ? (
                            <Box
                              style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Box style={{ flex: 1 }}>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador1
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador1 !== undefined
                                        ? buscarJugador(tirada.jugador1)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores1[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores1[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 50,
                                  }}
                                >
                                  {concurso.individual === true && (
                                    <Box
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        width: 70,
                                      }}
                                    >
                                      <Box style={{ paddingBottom: 5 }}>
                                        <IconoJugador
                                          jugador={
                                            tirada !== undefined
                                              ? tirada.jugador2
                                              : ''
                                          }
                                          size={'pequeño'}
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                  <Box
                                    style={{
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      flex: 1,
                                      marginLeft:
                                        concurso.individual === false ? 10 : 0,
                                    }}
                                  >
                                    <Texto
                                      numberOfLines={1}
                                      style={styles.instructions}
                                    >
                                      {tirada.jugador2 !== undefined
                                        ? buscarJugador(tirada.jugador2)?.nombre
                                        : `${
                                            buscarJugador(tirada.jugadores2[0])
                                              ?.nombre
                                          } - ${
                                            buscarJugador(tirada.jugadores2[1])
                                              ?.nombre
                                          }`}
                                    </Texto>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  width: 0.5,
                                  backgroundColor: 'grey',
                                  height: '100%',
                                }}
                              />
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 60,
                                }}
                              >
                                <Box style={{ paddingTop: 10 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos1 : 0}
                                  </Texto>
                                </Box>
                                <Box style={{ paddingTop: 20 }}>
                                  <Texto
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 'bold',
                                      color: 'white',
                                    }}
                                  >
                                    {tirada !== undefined ? tirada.bolos2 : 0}
                                  </Texto>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {ronda.ronda === 'Dieciseisavos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                  <Box style={{ height: 55 }} />
                  <LlavePequeña />
                </Box>
              )}
              {ronda.ronda === 'Octavos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveMediana />
                  <Box style={{ height: 120 }} />
                  <LlaveMediana />
                  <Box style={{ height: 120 }} />
                  <LlaveMediana />
                  <Box style={{ height: 120 }} />
                  <LlaveMediana />
                </Box>
              )}
              {ronda.ronda === 'Cuartos' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveGrande />
                  <Box style={{ height: 170 }} />
                  <LlaveGrande />
                </Box>
              )}
              {ronda.ronda === 'Semifinales' && (
                <Box
                  style={{
                    width: width * 0.2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 210,
                    marginBottom: 10,
                  }}
                >
                  <LlaveGigante />
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const styles = {
  loader: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centeredBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBox: {
    padding: 10,
    alignItems: 'center',
  },
  textInput: {
    width: '80%',
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderWidth: 1,
    marginBottom: 10,
    marginRight: 5,
  },
  textInput2: {
    height: 30,
    width: '30%',
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 16,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderWidth: 1,
    margin: 5,
  },
  instructions: {
    color: 'white',
    marginBottom: 5,
    fontSize: RFPercentage(1.5),
    maxWidth: '95%',
    fontWeight: 'bold',
  },
};

export default Ronda;
