export default function requireModalidad(nombre) {
  if (nombre === 'Bolo Palma') {
    return require('../assets/modalidades/boloPalma.png');
  } else if (nombre === 'Pasabolo Tablón') {
    return require('../assets/modalidades/pasaboloTablon.png');
  } else if (nombre === 'Pasabolo Losa') {
    return require('../assets/modalidades/pasaboloLosa.png');
  } else if (nombre === 'Bolo Pasiego') {
    return require('../assets/modalidades/boloPasiego.png');
  } else {
    return null;
  }
}
